import React, { useContext, useState } from "react";
import { AuthContext } from "../../../services/auth/AuthProvider";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ORGANIZATION, DELETE_MENTORING_LOCATION } from "../services/graphql";
import Modal from "../../../components/Modal";
import SideModal from "../../../components/SideModal";
import FormErrors from "../../../components/FormErrors";
import LocationForm from "../components/LocationForm";
import { useFilteredList } from "../../../hooks/useFilteredList";

import { Edit, Plus, Trash2 } from "react-feather";

const Locations = () => {

    const { currentUser } = useContext(AuthContext);

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const { data, loading, error } = useQuery(GET_ORGANIZATION, {
        variables: {
            organizationId: currentUser?.organization.id
        }
    });


    const { term, handleTermChange, itemsToShow, filteringReady } = useFilteredList(data?.organization?.mentoringLocations || [], 100);


    const [deleteMentoringLocation, { loading:deleting }] = useMutation(DELETE_MENTORING_LOCATION, {
        onCompleted: data => {
            if (data.deleteMentoringLocation.errors && data.deleteMentoringLocation.errors.length > 0) {
                setFormErrors(data.deleteMentoringLocation.errors);
                return;
            }
            data.deleteMentoringLocation?.deleted && setConfirmDelete(null);
        },
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId: currentUser?.organization.id } }]
    });


    if (loading) {
        return <p>Loading...</p>
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.organization && currentUser.isOrganizationAdmin) {

        const { organization } = data;

        return (

            <>
                <div className="mb-3 d-flex align-items-center justify-content-between">
                    <h1>Manage Locations</h1>
                    <div className="d-flex align-items-center">
                        <span className='mr-2'>
                            <input
                                onChange={handleTermChange}
                                placeholder='Search '
                                className='basic-form__text-box mb-0'
                                type="search"
                                value={term}
                                results={+true} />
                        </span>
                        <button type="button" className="btn btn-blue d-flex px-1" onClick={() => setAdd(true)}><Plus /></button>

                    </div>
                </div>
                {itemsToShow.length > 0 ?
                    <ul className="list-group">
                        {itemsToShow.map(location => (
                            <li key={location.id} className="list-item list-item--separator-dark justify-content-between">
                                <div className="flex-grow">
                                    <p>{location.name}</p>
                                    <p className="small">
                                        {location.address1}
                                        {location.address2 && `, ${location.address2}`}
                                        {location.address3 && `, ${location.address3}`}
                                        {location.city && `, ${location.city}`}
                                        {location.zipCode && `, ${location.zipCode}`}
                                    </p>
                                </div>
                                <div>
                                    <button type="button" onClick={() => setEdit(location)} className="text-blue mr-2"><Edit /></button>
                                    <button type="button" onClick={() => setConfirmDelete(location)} className="text-blue"><Trash2 /></button>
                                </div>
                            </li>
                        ))}
                    </ul> : filteringReady && <p>No locations {term.length === 0 ? 'added' : 'match your search'}</p>}

                {add &&
                    <SideModal closeModal={() => setAdd(false)}>
                        <LocationForm organizationId={organization.id} close={() => setAdd(false)} />
                    </SideModal>
                }

                {edit &&
                    <SideModal closeModal={() => setEdit(null)}>
                        <LocationForm organizationId={organization.id} itemToEdit={edit} close={() => setEdit(null)} />
                    </SideModal>
                }

                {confirmDelete && (
                    <Modal closeModal={() => setConfirmDelete(false)}>
                        <div className="text-center">
                            <h1 className="mb-2">Are you sure?</h1>
                            {formErrors && <FormErrors errors={formErrors} />}
                            <button
                                disabled={deleting}
                                type="button"
                                className="btn btn-blue mr-2"
                                onClick={() => deleteMentoringLocation({
                                    variables: {
                                        mentoringLocationId: confirmDelete.id
                                    }
                                })}>Yes</button>
                            <button type="button" className="btn btn-muted" onClick={() => setConfirmDelete(null)}>No</button>
                        </div>
                    </Modal>
                )}
            </>
        )
    }
    return null;
}

export default Locations;