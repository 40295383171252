import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useLazyQuery } from '@apollo/client';
import { ORGANIZATION_REPORT_DATA, OFFICE_REPORTS } from '../services/graphql';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { htmlLegendPlugin, getColors } from '../utils';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

const HoursCaseType = ({ caseTypes, organization, office }) => {

    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    //const minDate = moment(organization.createdAt).subtract(5, 'month').toDate();

    const minDate = moment(office ? office.createdAt : organization.createdAt).toDate();

    const [getMonthHoursPerCaseType] = useLazyQuery(ORGANIZATION_REPORT_DATA, {
        fetchPolicy: 'network-only'
    });

    const [getOfficeMonthHoursPerCaseType] = useLazyQuery(OFFICE_REPORTS, {
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (organization) {
            getMonthHoursPerCaseType({
                variables: {
                    organizationId: organization.id,
                    month: +moment(selectedDate).format('MM-YYYY').split('-')[0],
                    year: +moment(selectedDate).format('MM-YYYY').split('-')[1]
                }
            })
        } else if (office) {
            getOfficeMonthHoursPerCaseType({
                variables: {
                    officeId: office.id,
                    month: +moment(selectedDate).format('MM-YYYY').split('-')[0],
                    year: +moment(selectedDate).format('MM-YYYY').split('-')[1]
                }
            })
        }
    }, [selectedDate]);

    const sortedTypes = [...caseTypes].sort((a, b) => (b.value || 0) - (a.value || 0));

    const data = {
        labels: [...sortedTypes.map(type => type.name)],
        datasets: [
            {
                data: [...sortedTypes.map(el => el.value)],
                backgroundColor: getColors(sortedTypes, 'value', sortedTypes.length),
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            htmlLegend: {
                containerID: 'legend-container',
            },
            legend: {
                display: false,
            }
        },
    };

    return (
        <div className={`${"card mb-3"} ${organization ? "card-blue" : ""}`}>
            <div className="card-body">
                <h3>Number of Hours per case type</h3>
                <div className="row">
                    <div className="col-md-3">
                        <ReactDatePicker
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            onChange={(date) => setSelectedDate(date)}
                            className="basic-form__text-box picker"
                            selected={selectedDate}
                            minDate={minDate}
                            maxDate={moment().toDate()}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <Doughnut
                            plugins={[htmlLegendPlugin]}
                            data={data}
                            options={options}
                            height={180}
                        />
                    </div>
                    <div className="col-md-8">
                        <div id="legend-container"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HoursCaseType;