import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_ORG_REFERRALS, GET_OFFICE_REFERRALS, CREATE_PDF_REFERRAL } from "../services/graphql";
import ReactDatePicker from "react-datepicker";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import "react-datepicker/dist/react-datepicker.css";
import FormErrors from '../../../components/FormErrors';
import ViewReferral from "../ViewReferral";
import { AuthContext } from "../../../services/auth/AuthProvider";
import styles from '../Referrals.module.scss';
import { PlusCircle } from "react-feather";

const CreateReferralPdf = ({ close, officeId, setShowAlert, organization, onShowReferral }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [showReferral, setShowReferral] = useState(null)


    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [selectedFile, setSelectedFile] = useState(null);

    const onSelectFile = (files) => {
        if (files.length) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null)
        }
    }

    const referralQueries = () => {
        if (officeId) {
            return [
                { query: GET_ORG_REFERRALS, variables: { organizationId: organization.id }, },
                { query: GET_OFFICE_REFERRALS, variables: { officeId } },
            ]
        }
        return [{ query: GET_ORG_REFERRALS, variables: { organizationId: organization.id } }]
    }

    const [createReferral, { loading }] = useMutation(CREATE_PDF_REFERRAL, {
        onCompleted: data => {
            if (data?.createReferral?.errors?.length > 0) {
                setFormErrors(data.createReferral.errors);
                return;
            }
            if (data?.createReferral?.referral?.id) {
                const newRef = data?.createReferral?.referral;
                const newCu = { ...currentUser, organization: { ...currentUser.organization, referrals: [...currentUser.organization.referrals, newRef] } }
                setCurrentUser(newCu);
                !officeId && setShowAlert(newRef.office ? 'New referral created and assigned' : 'New referral created');
                officeId ? setShowReferral(data.createReferral.referral) : close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => referralQueries()
    });

    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        referralDate: Yup.string().required('Please select a date'),
        file: Yup.mixed().test("file", "You need to provide a file", (value) => {
            if (value.length > 0) {
                return true;
            }
            return false;
        }),
    });
    const {
        register,
        handleSubmit,
        control,
        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            referralOffice: officeId
        }
    });

    const onSubmit = data => {
        const { firstName, lastName, referralDate, referralOffice, file } = data;
        createReferral({
            variables: {
                organizationId: organization.id,
                firstName,
                lastName,
                ...(referralOffice && { officeId: referralOffice }),
                file: file[0],
                referralDate
            }
        });
    }

    return (
        <>
            {showReferral ? (
                <ViewReferral
                    organization={organization}
                    officeId={officeId}
                    referralItem={showReferral}
                />
            ) :
                <>
                    <h2>Create New Referral</h2>
                    <form className='form' onSubmit={handleSubmit(onSubmit)}>

                        <div className="basic-form__group">
                            <label htmlFor='name' className='basic-form__text-label'>Young Person First Name</label>
                            <input
                                {...register('firstName')}
                                placeholder="First name"
                                className="basic-form__text-box" />
                            {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                        </div>
                        <div className="basic-form__group">
                            <label htmlFor='name' className='basic-form__text-label'>Young Person Last Name</label>
                            <input
                                {...register('lastName')}
                                placeholder="Last name"
                                className="basic-form__text-box" />
                            {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                        </div>


                        <div className="basic-form__group">
                            <label htmlFor='referralDate' className='basic-form__text-label'>Date of Referral</label>
                            <Controller
                                control={control}
                                name="referralDate"
                                defaultValue={''}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        {...field}
                                        dateFormat={'dd/MM/yyyy'}
                                        autoComplete='off'
                                        className="basic-form__text-box picker"
                                        placeholderText="Select date"
                                        selected={field.value}
                                    />
                                )}
                            />
                            {errors.referralDate && <p className='basic-form__hint'>{errors.referralDate.message}</p>}
                        </div>

                        <p className="uppercase text-gray-2 bold small mb-1">Upload Referral Doc</p>
                        <>
                            <div style={{ display: selectedFile ? 'none' : 'flex' }} className="basic-form__group relative buttonContainer">
                                <input
                                    {...register('file')}
                                    onChange={e => onSelectFile(e.target.files)}
                                    type='file'
                                    id="file"
                                    className={styles.inputFile} />
                                <label htmlFor='file'><PlusCircle size='20' className={styles.buttonIcon} />Select File</label>
                            </div>
                            {errors.file && !selectedFile && <p className='basic-form__hint'>{errors.file.message}</p>}
                        </>
                        {selectedFile && <p className="mb-2">{selectedFile.name}</p>}

                        <p className="mb-1 lead">Assign to:</p>
                        {!officeId ?
                            <div className="basic-form__group mb-4">
                                <select
                                    {...register('referralOffice')}
                                    className="basic-form__text-select mb-0 mr-1"
                                >
                                    <option value=''>Please select</option>
                                    {currentUser.organization.offices.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                                </select>
                            </div> :
                            <>
                                <div className="basic-form__group mb-4">
                                    <input type="hidden" {...register('referralOffice')} defaultValue={officeId} />
                                    <select disabled
                                        className="basic-form__text-select mb-0 mr-1"
                                    >
                                        <option>{currentUser.organization.offices.find(o => o.id === officeId)?.name}</option>
                                    </select>
                                </div>
                            </>
                        }

                        {formErrors && <FormErrors errors={formErrors} />}

                        <button type="submit" disabled={loading} className="btn">Create Referral</button>
                        <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                    </form>
                </>
            }
        </>
    )
}

export default CreateReferralPdf;