import React, { useContext, useEffect, useState } from "react";
import { MENTORING_CATEGORY_TYPES } from "../../../utils/constants";
import CategoriesWrapper from "../components/CategoriesWrapper";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../services/graphql";
import { AuthContext } from "../../../services/auth/AuthProvider";
import { useLocation } from "react-use";

const SessionCategories = () => {

    const location = useLocation();
    const [currentCateg, setCurrentCateg] = useState(null)
    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(GET_ORGANIZATION, {
        variables: {
            organizationId: currentUser?.organization.id
        }
    });

    const categoryMapping = {
        '/settings/session-types': MENTORING_CATEGORY_TYPES.sessionCateg,
        '/settings/session-themes': MENTORING_CATEGORY_TYPES.sessionTheme,
        '/settings/cancellation-reasons': MENTORING_CATEGORY_TYPES.cancellationReason,
        '/settings/session-breaks': 'SessionBreaks'
    };

    useEffect(() => {
        setCurrentCateg(categoryMapping[location.pathname] || null);
    }, [location.pathname]);

    const getItems = org => {
        switch (currentCateg) {
            case MENTORING_CATEGORY_TYPES.cancellationReason:
                return org.mentoringCancellationReasons;
            case MENTORING_CATEGORY_TYPES.sessionTheme:
                return org.mentoringSessionPlannedThemes;
            case MENTORING_CATEGORY_TYPES.sessionCateg:
                return org.mentoringSessionCategories;
            case 'SessionBreaks':
                return org.sessionThreshold || []
            default:
                return [];
        }
    }


    if (loading) {
        return <p>Loading...</p>
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.organization && currentCateg) {

        const { organization } = data;

        return (
            <>
                <CategoriesWrapper type={currentCateg} items={getItems(organization)} organization={organization} />
            </>
        )
    }
    return null
}

export default SessionCategories;