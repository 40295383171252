import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { navigate } from '@reach/router';
import { CONFIRM_ORG_ADMIN_USER } from "../../services/graphql";
import styles from '../../Login.module.scss';
import cx from 'classnames';
import FormErrors from "../../../../components/FormErrors";

const ConfirmOrgAdminUser = ({ code }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [confirmOrganizationAdminUser] = useMutation(CONFIRM_ORG_ADMIN_USER, {
        onCompleted: data => {
            if (data.confirmOrganizationAdminUser.errors && data.confirmOrganizationAdminUser.errors.length !== 0) {
                setFormErrors(data.confirmOrganizationAdminUser.errors);
                return;
            }
            if (data.confirmOrganizationAdminUser.user && data.confirmOrganizationAdminUser.user.id) {
                navigate('/', { state: { account_activated: true } });
            }
        },
    });

    useEffect(() => {
        confirmOrganizationAdminUser({
            variables: {
                code
            }
        })
    }, []);

    return (

        <div className='row'>
            <div className='col-md-6 mx-auto'>
                <div className={cx('card', styles.login)}>
                    <div className="card-body text-center">
                        <h3 className="mt-3 mb-3">Activating account, please wait...</h3>
                        {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ConfirmOrgAdminUser;