import { gql } from '@apollo/client';

export const LOGIN_INIT = gql`
mutation loginInit($email: String!) {
    loginInit(email: $email) {
        emailConfirmed
        errors
    }
}
`;

export const LOGIN_USER = gql`
mutation loginUser($email: String!, $code: String!, $scope: Int) {
    loginUser(email: $email, code: $code, scope: $scope) {
        token
        errors
        currentUser {
            id
            firstName
            lastName
            email
            isOrganizationAdmin
            photoUrl
        }
    }
}
`;

export const CONFIRM_ORG_ADMIN_USER = gql`
mutation confirmOrganizationAdminUser($code: String!) {
    confirmOrganizationAdminUser(code: $code) {
        errors
        user {
            id
            firstName
            lastName
            email
        }
    }
}
`;