import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import AddDocument from './AddDocument';
import ResourceDocument from './components/ResourceDocument';
import { DOCUMENTS } from './services/graphql';
import { DOCUMENT_TYPES } from '../../utils/constants';
import styles from './ResourceCentre.module.scss';
import { AuthContext } from '../../services/auth/AuthProvider';

const ResourceCentre = () => {

	const { currentUser } = useContext(AuthContext);

	const [addDoc, setAddDoc] = useState(false);
	const [selectedTab, setSelectedTab] = useState('all');
	const [docs, setDocs] = useState([]);

	const [loadDocuments, { data, loading }] = useLazyQuery(DOCUMENTS, {
		fetchPolicy: 'cache-and-network',
		onCompleted: data => {
			setDocs(data.documents);
		}
	});

	useEffect(() => {
		if (data && data.documents !== docs) {
			setDocs(data.documents);
		}
	}, [data, docs]);

	useEffect(() => {
		loadDocuments({
			variables: {
				documentType: selectedTab === 'all' ? null : selectedTab
			}
		})
	}, [selectedTab, loadDocuments]);


	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-sm-flex justify-content-between">
						<h2>Resource Center</h2>
						{currentUser.isOrganizationAdmin &&
							<button onClick={() => setAddDoc(true)} className="btn btn-primary">+ Document</button>
						}
					</div>
					<Tabs withPanel className='mb-3'>
						<Tab onClick={() => setSelectedTab('all')} selected={selectedTab === 'all'}>All</Tab>
						<Tab onClick={() => setSelectedTab(DOCUMENT_TYPES.training)} selected={selectedTab === DOCUMENT_TYPES.training}>Training</Tab>
						<Tab onClick={() => setSelectedTab(DOCUMENT_TYPES.information)} selected={selectedTab === DOCUMENT_TYPES.information}>Information</Tab>
						<Tab onClick={() => setSelectedTab(DOCUMENT_TYPES.template)} selected={selectedTab === DOCUMENT_TYPES.template}>Template</Tab>
						<Tab onClick={() => setSelectedTab(DOCUMENT_TYPES.administration)} selected={selectedTab === DOCUMENT_TYPES.administration}>Administration</Tab>
					</Tabs>

					{loading ? <Loader /> :
						docs.length > 0 ?
							<ul className={styles.docList}>
								{docs.map(doc => <ResourceDocument currentUser={currentUser} sortFilter={selectedTab} key={doc.id} documentResource={doc} />)}
							</ul> : <p>No documents uploaded yet</p>

					}
				</div>
			</div>

			{addDoc && (
				<Modal closeModal={() => setAddDoc(false)}>
					<AddDocument sortFilter={selectedTab} onCancel={() => setAddDoc(false)} />
				</Modal>
			)}
		</>
	)

}

export default ResourceCentre;
