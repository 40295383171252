import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../services/auth/AuthProvider";
import { useApolloClient } from "@apollo/client";
import { ChevronDown, ChevronsLeft, ChevronsRight, Menu, LogOut, Sidebar as SideBarIcon } from "react-feather";
import ImageFadeIn from "react-image-fade-in";
import ls from 'local-storage';
import logo from '../../images/logo.png';
import { useLocation } from "react-use";
import { Link, navigate } from '@reach/router';
import defaultImg from '../../images/icons/user.png';
import styles from './Sidebar.module.scss';
import styles2 from '../Dropdown/Dropdown.module.scss';
import OfficeAdminMenu from "./OfficeAdminMenu";
import { REFERRAL_STATUS } from "../../utils/constants";

const pattern = /\/offices\/([^/]+)/;

const Sidebar = ({ setCollapseSide, collapseSide }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const location = useLocation();
    const officesRef = useRef(null);
    const [showOfficeMenu, setShowOfficeMenu] = useState(false);


    const selectedOrgAdminOffice = () => {
        const match = location.pathname.match(pattern);
        if (match && match[1] && currentUser) {
            const office = currentUser.organization?.offices?.find(org => org.id === match[1]);
            return office
        }
    }

    const client = useApolloClient();

    const [hovered, setHovered] = useState(false);

    const officeAdminMemberships = currentUser.officeMemberships?.filter(m => m.isAdmin);

    const newOrgReferrals = currentUser.organization?.referrals?.filter(r => r.status === REFERRAL_STATUS.new).length;

    const getMainSection = () => {
        if (location.pathname === '/') {
            return 'Dashboard'
        }
        if (selectedOrgAdminOffice()) {
            return `Office:  ${selectedOrgAdminOffice().name}`
        }
        if (location.pathname === '/team') {
            return 'Team'
        }

        if (location.pathname === '/referrals') {
            return 'Referrals'
        }
        if (location.pathname === '/reports') {
            return 'Reports'
        }
        if (location.pathname === '/training') {
            return 'Training'
        }
        if (location.pathname === '/allOffices') {
            return 'All offices'
        }
        if (location.pathname === '/resourceCentre') {
            return 'Resources'
        }
        return null
    }

    const logout = () => {
        ls.remove('restorativ_admin_user');
        ls.remove('restorativ_admin_token');
        setCurrentUser(null);
        client.clearStore();
        navigate('/');
    }

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: `${styles.sidebarMainNavLink} ${styles.sidebarMainNavLinkActive}` } : { className: styles.sidebarMainNavLink }
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: `${styles.sidebarMainNavLink} ${styles.sidebarMainNavLinkActive}` } : { className: styles.sidebarMainNavLink }
    }

    const handleHover = () => {
        if (collapseSide) {
            setHovered(true)
        }
    }

    useEffect(() => {
        if (hovered) {
            setHovered(false);
        }
    }, [location]);


    return (
        <>
            <div className={styles.topbar}>
                {collapseSide &&
                    <div className={styles.sidebarHoverIcon} onMouseEnter={() => handleHover()} onMouseLeave={() => handleHover()} ><Menu /></div>
                }
                <div className={`${styles.topbarContent} ${!collapseSide ? styles.topbarContentExpanded : ''}`}>
                    {getMainSection()}
                </div>
            </div>

            <div onMouseLeave={() => collapseSide && hovered && setHovered(false)} className={`${styles.sidebar} ${collapseSide ? styles.sidebarCollapsed : ''}  ${hovered ? styles.sidebarHovered : ''}`}>
                {!collapseSide && <div className={styles.sidebarToggleIcon} onClick={() => { setCollapseSide(!collapseSide); setHovered(false) }}><SideBarIcon /></div>}
                {collapseSide && hovered && <div className={styles.sidebarToggleIcon} onClick={() => { setCollapseSide(!collapseSide); setHovered(false) }}><ChevronsRight /></div>}
                <div className={styles.sidebarMain}>
                    <div className="d-flex items-center mb-3">
                        {currentUser.isOrganizationAdmin && location.pathname.includes('offices') ?
                            <Link to='/' className={`${styles.borderedLink} ${'mb-2'}`}>
                                <ChevronsLeft className="mr-2" />
                                <div>
                                    <p className="small">Back to</p>
                                    <p>{currentUser.organization.name}</p>
                                </div>
                            </Link> :
                            <div className={`${styles.borderedLink} ${'mb-2'}`}>
                                {currentUser.organization?.logo && <ImageFadeIn className={styles.sidebarMainOrgLogo} src={currentUser.organization.logo} alt='Organization logo' />}
                                <span>{currentUser.organization?.name}</span>
                            </div>
                        }
                    </div>

                    <ul className={styles.sidebarMainNav}>
                        {currentUser.isOrganizationAdmin ? <>
                            {location.pathname.includes('/offices/') ?
                                <OfficeAdminMenu currentUser={currentUser} currentOrgAdminOffice={selectedOrgAdminOffice()} offices={currentUser.organization.offices} />
                                :
                                <>
                                    <li><Link to='/' getProps={isActive}>Reports</Link></li>
                                    <li><Link to='/team' getProps={isActive}>Team</Link></li>
                                    <li><Link to='/resourceCentre' getProps={isActive}>Resource Center</Link></li>
                                    <li><Link to='/training' getProps={isPartiallyActive}>Training</Link></li>

                                    {currentUser.organization?.mentoringEnabled &&
                                        <li>
                                            <Link to='/referrals' getProps={isActive}>
                                                <span className="d-flex align-items-center justify-content-between">
                                                    Referrals
                                                    {newOrgReferrals > 0 && <span className="label label-primary">{newOrgReferrals}</span>}
                                                </span>
                                            </Link>
                                        </li>
                                    }

                                    {currentUser.organization?.mentoringEnabled &&
                                        <li><Link to='/settings' getProps={isActive}>Settings</Link></li>
                                    }
                                    <li className="text-muted-2 uppercase bold small mb-2 mt-3">Offices</li>
                                    {currentUser.organization?.offices?.length > 1 ?
                                        <>
                                            <li className="mb-2">
                                                <div ref={officesRef} className={`${styles2.dropdown} ${'width-full'}`}>
                                                    <button className={`${styles.borderedLink} ${'d-flex justify-content-between'}`} onClick={() => setShowOfficeMenu(!showOfficeMenu)}>Jump to Office <ChevronDown /></button>
                                                    {showOfficeMenu &&
                                                        <ul className={`${styles2.dropdownMenu} ${styles2.dropdownMenuExpand} ${'width-full'}`}>
                                                            {currentUser.organization?.offices.map(office =>
                                                                <li key={`link-${office.id}`}><Link onClick={() => setShowOfficeMenu(false)} to={`/offices/${office.id}`}>{office.name}</Link></li>
                                                            )}
                                                        </ul>
                                                    }
                                                </div>
                                            </li>
                                            <li><Link to='/allOffices' getProps={isPartiallyActive}>All Offices</Link></li>
                                        </> : currentUser.organization?.offices?.length === 1 &&

                                        <li><Link to={`/offices/${currentUser.organization.offices[0].id}`} getProps={isPartiallyActive}>{currentUser.organization?.offices[0].name}</Link></li>

                                    }
                                </>
                            }
                        </> :
                            officeAdminMemberships.length > 0 && <OfficeAdminMenu currentUser={currentUser} offices={officeAdminMemberships.map(om => om.office)} />
                        }
                        {!currentUser.isOrganizationAdmin && (
                            <>
                                {currentUser.officeMemberships.find(om => om.isCircleLeader) && officeAdminMemberships.length === 0 &&
                                    <li><Link to='/' getProps={isActive}>My Cases</Link></li>
                                }
                                <li><Link to='/resourceCentre' getProps={isActive}>Resource Center</Link></li>
                                <li><Link to='/training' getProps={isPartiallyActive}>Training</Link></li>
                            </>
                        )}
                        {/* // IF user is BOTH CL && (ORG AMDMIN  OR Office Admin)*/}
                        {currentUser.officeMemberships?.find(om => om.isCircleLeader) && (currentUser.isOrganizationAdmin || officeAdminMemberships.length > 0) &&
                            <li className="mt-3"><Link to='/myCases' getProps={isActive}>My Cases</Link></li>
                        }

                    </ul>

                    <div className={styles.sidebarFooter}>
                        <Link className={`${styles.sidebarFooterLink} ${styles.borderedLink}`} to='/profile'><ImageFadeIn width={30} src={currentUser.photoUrl || defaultImg} alt='' /> My Account</Link>
                        <button className={`${styles.sidebarFooterLink} ${styles.borderedLink}`} onClick={() => logout()}><LogOut size={30} /> Logout</button>
                        <div className="mt-2">
                            <ImageFadeIn width='100' src={logo} alt='Restorativ logo' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;