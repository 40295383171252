import React, { useState } from 'react';
import Loader from '../../components/Loader';
import { OFFICE_REPORTS } from './services/graphql';
import { useQuery, useLazyQuery } from '@apollo/client';
import ImageFadeIn from 'react-image-fade-in';
import CategoriesBreakdown from './components/CategoriesBreakdown';
import CircleLeadersHours from './components/CircleLeadersHours';
import HoursCaseType from './components/HoursCaseType';
import styles from './Reports.module.scss'
import cx from 'classnames';
import Modal from '../../components/Modal';
import moment from 'moment';

const OfficeReportsContent = ({ officeId }) => {


    const [showThreats, setShowThreats] = useState(false);

    const { data, loading, error } = useQuery(OFFICE_REPORTS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            officeId
        }
    });

    const currentYear = moment().year();

    const getYears = (startYear, currentYear) => {
        let years = []
        while (startYear <= currentYear) {
            years.push(startYear);
            startYear = startYear + 1;
        }
        return years;
    }

    const [getThreatReports] = useLazyQuery(OFFICE_REPORTS, {
        fetchPolicy: 'network-only',
    });



    if (loading) return <Loader />;

    if (error) return <p>{error?.message}</p>;

    if (data?.office) {

        const { office } = data;
        const officeStartYear = office && moment(office.createdAt, "YYYY-MM-DD").year();

        return (
            <>
                <h2>{office.name} reports</h2>
                <CategoriesBreakdown reports={office.reports} />
                <div className="mb-3">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>Average Timing from Referred to Pre Circle</div>
                                    <span className='label label-primary'>{office.reports.averages.timeToPrecircle}</span>
                                </div>
                            </div>
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>Average Timing from Pre Circle to close</div>
                                    <span className='label label-primary'>{office.reports.averages.timeFromPrecircleToClose}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>Average time per case</div>
                                    <span className='label label-primary'>{office.reports.averages.timePerCase}</span>
                                </div>
                            </div>
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>Time logs on each case</div>
                                    <span className='label label-primary'>{office.reports.averages.timeLogs}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <CircleLeadersHours
                    leaders={office.leaders.filter(l => l.allCases.length > 0)}
                    officeId={officeId}
                    office={office}
                    years={getYears(officeStartYear, currentYear)}
                />

                <HoursCaseType
                    office={office}
                    caseTypes={office.totalHoursPerCaseTypePerMonth}
                />

                <div className='card mb-2'>
                    <div className='card-body'>
                        <div className=" d-flex align-items-center justify-content-between">
                            <div className='d-flex align-items-center'>
                                <h3 className='mb-0'>Number of Threat Reports</h3>
                                <select className='basic-form__text-select ml-1' defaultValue={currentYear} onChange={e => getThreatReports({
                                    variables: {
                                        threatReportYear: +e.target.value,
                                        officeId,
                                    }
                                })}>
                                    {getYears(officeStartYear, currentYear).reverse().map(el => (
                                        <option key={`year-${el}`} value={el}>{el}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <span className='label label-primary'>{office.reports.threatReportsCounter}</span>
                                <button onClick={() => setShowThreats(!showThreats)} className='btn ml-2'>View</button>
                            </div>
                        </div>
                        {showThreats &&
                            <Modal wide closeModal={() => setShowThreats(false)}>
                                <h1>Threat reports</h1>

                                {office.allThreats.length === 0 ? <p>No threats</p> :
                                    [...office.allThreats].sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map(threat =>
                                        <div key={threat.id}>
                                            <div className="d-flex alig-items-center justify-content-between">
                                                <div>
                                                    <p>{threat.author?.firstName} {threat.author?.lastName} <span className='u-m-left-2 small'> - {moment(threat.createdAt).format(' DD MMMM YYYY')}</span> </p>
                                                </div>
                                                <p>Circle: {threat.circle?.name}</p>
                                            </div>
                                            <p>{threat.details}</p>
                                            <hr className='separator mt-1 mb-1' />
                                        </div>
                                    )
                                }
                            </Modal>
                        }
                    </div>
                </div>
                <div className="mb-3">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>%  of outcomes reached</div>
                                    <span className='label label-primary'>{office.reports.outcomesReachedPercentage || 0}</span>
                                </div>
                            </div>
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>% of Ethnicities supported</div>
                                    <span className='label label-primary'>{office.reports.bamePercentage || 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>Number of AP supported</div>
                                    <span className='label label-primary'>{office.numberOfAffectedPartiesSupported}</span>
                                </div>
                            </div>
                            <div className='card mb-2'>
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div>Number of RP supported</div>
                                    <span className='label label-primary'>{office.numberOfResponsiblePartiesSupported}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h2>Persona breakdown</h2>
                        <ul className={styles.personaList}>
                            {office.reports.personas.map((persona, i) => {
                                const personaImage = `../../images/persona/${persona.name}.png`;
                                return (
                                    <li className={styles.personaListItem} key={i}>
                                        <div className={styles.personaListTitle}>{persona.name}</div>
                                        <div className={styles.personaListImg}><ImageFadeIn src={personaImage} alt={persona.name} /></div>
                                        <div className={cx('label label-muted', styles.personaListPerc)}>{persona.value}%</div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default OfficeReportsContent;
