import React, { useState, useEffect} from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_CIRCLES } from "../services/grapqhl";
import CaseItem from "../components/CaseItem";
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import AddCase from "../components/AddCase";

const TABS = {
    activeCases: 1,
    archivedCases: 2,
}

const MyCases = () => {

    const [selectedTab, setSelectedTab] = useState(TABS.activeCases);
    const [sortedCases, setSortedCases] = useState([]);
    const [baseCases, setBaseCases] = useState(sortedCases);
    const [addCase, setAddCase] = useState(false);

    const [sortType, setSortType] = useState('');


    const [getCases, { data, loading, error }] = useLazyQuery(GET_USER_CIRCLES, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setBaseCases(data.currentUser?.cases);
        }
    });

    useEffect(() => {
        if (data?.currentUser && data.currentUser.cases !== baseCases) {
            setBaseCases(data.currentUser.cases);
        }
    }, [data, baseCases]);


    useEffect(() => {
        getCases({
            variables: {
                archived: selectedTab === TABS.archivedCases,
                includeDrafts: true
            }
        })
    }, [selectedTab, getCases]);

    useEffect(() => {
        if (sortType === 'oldest') {
            setSortedCases([...baseCases].sort((a, b) => a.createdAt.localeCompare(b.createdAt)))
        } else if (sortType === 'recent') {
            setSortedCases([...baseCases].sort((a, b) => b.createdAt.localeCompare(a.createdAt)))
        } else if (sortType === 'caseType') {
            setSortedCases([...baseCases].sort((a, b) => (a.caseType === null) - (b.caseType === null) || +(a.caseType > b.caseType) || -(a.caseType < b.caseType)));
        }
        else if (sortType === 'caseNumber') {
            setSortedCases([...baseCases].sort((a, b) => a.caseNumber.localeCompare(b.caseNumber)))
        } else {
            setSortedCases(baseCases);
        }
    }, [sortType, baseCases]);



    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <>Loading</>
    }
    if (data?.currentUser) {
        return (
            <> {addCase ? <AddCase toLeader={data.currentUser} onCancel={() => setAddCase(false)} /> :
                
                <>
                    <h2>My Cases</h2>

                    <div className="d-sm-flex justify-content-between">
                        <Tabs withPanel className='u-m-base-3'>
                            <Tab onClick={() => setSelectedTab(TABS.activeCases)} selected={selectedTab === TABS.activeCases}>Active cases</Tab>
                            <Tab onClick={() => setSelectedTab(TABS.archivedCases)} selected={selectedTab === TABS.archivedCases}>Archived cases</Tab>
                            <select defaultValue={sortType} className='basic-form__text-select' onChange={(e) => setSortType(e.target.value)}>
                                <option value="">View by</option>
                                <option value="recent">Most recent</option>
                                <option value="oldest">Oldest</option>
                                <option value="caseType">Case Type</option>
                                <option value="caseNumber">Case Number</option>
                            </select>
                        </Tabs>
                        <button onClick={() => setAddCase(true)} className="btn btn-primary">+ Case</button>

                    </div>
                    {
                        sortedCases.length > 0 ? (
                            <ul className='row mt-3'>
                                {
                                    sortedCases.map(caseItem => (
                                        <div key={caseItem.id} className='col-md-3 col-sm-4 d-flex mb-2'>
                                            <CaseItem fromMyCases={true} caseItem={caseItem} />
                                        </div>
                                    ))
                                }
                            </ul>
                        ) : <p>No cases</p>
                    }
                </>
    }
            </>
        )
    }
    return null;
}

export default MyCases;