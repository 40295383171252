import React, { useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GET_CASE, EDIT_CIRCLE_MEMBER, GET_USER_PROFILE_ETHNICITIES } from '../../../services/grapqhl';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import Loader from "../../../../../components/Loader";
import FormErrors from '../../../../../components/FormErrors';
import { circleMembership, CIRCLE_MODE } from "../../../../../utils/constants";

const ParticipantEdit = ({ circle, role, close, member, needsConfirmation = false }) => {

    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_USER_PROFILE_ETHNICITIES);


    const showBameRequired = role === circleMembership.affectedParty || role === circleMembership.responsibleParty || role === circleMembership.participant


    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        ...(showBameRequired && { bame: Yup.string().required('Ethnicity is required') }),
        email: Yup.string().when('requiresEmail', {
            is: true,
            then: Yup.string().required('Add email')
        })
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: {
            errors
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            requiresEmail: needsConfirmation ? true : member.user.requiresEmail
        }
    });

    const watchRequiresEmail = watch('requiresEmail');



    const [editCircleMember, { loading: adding }] = useMutation(EDIT_CIRCLE_MEMBER, {
        onCompleted: data => {
            if (data.editCircleMember.errors && data.editCircleMember.errors.length > 0) {
                setFormErrors(data.editCircleMember.errors);
                return;
            }
            if (data.editCircleMember.circleMember?.id) {
                close && close()
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE,
                variables: {
                    circleId: circle.id,
                }
            }
        ],
    });

    const onSubmit = data => {
        const { firstName, lastName, address, contactNumber, previousRecord, healthIssues, parentDetails, otherRelevantInformation, bame, requiresEmail } = data;
        editCircleMember({
            variables: {
                circleId: circle.id,
                role: +role,
                userData: {
                    uniqueMemberCode: member.user.uniqueMemberCode,
                    email: member.user.email,
                    firstName,
                    lastName,
                    requiresEmail: needsConfirmation ? requiresEmail : member.user.requiresEmail,
                    contactNumber: contactNumber !== '' ? contactNumber : null,
                    previousRecord,
                    address: address !== '' ? address : null,
                    healthIssues: healthIssues !== '' ? healthIssues : null,
                    parentDetails: parentDetails !== '' ? parentDetails : null,
                    otherRelevantInformation: otherRelevantInformation !== '' ? otherRelevantInformation : null,
                    ...(bame && { bame: +bame }),
                }
            }
        });
    }

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.userProfileGendersEthnicitiesAndCountries?.ethnicities) {
        const { userProfileGendersEthnicitiesAndCountries: { ethnicities } } = data;

        return <>
            <h1>{needsConfirmation ? 'Confirm mentee details' : 'Edit participant'}</h1>

            <form className='form mb-4' onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-6">

                        <div className="basic-form__group">
                            <label htmlFor='firstName' className='basic-form__text-label'>First name* (required)</label>
                            <input
                                {...register('firstName')}
                                placeholder="First name"
                                type='text'
                                defaultValue={member.user.firstName}
                                className="basic-form__text-box" />
                            {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="basic-form__group">
                            <label htmlFor='lastName' className='basic-form__text-label'>Last name* (required)</label>
                            <input
                                {...register('lastName')}
                                placeholder="Last name"
                                type='text'
                                defaultValue={member.user.lastName}
                                className="basic-form__text-box" />
                            {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="basic-form__group">
                            <label htmlFor='contactNumber' className='basic-form__text-label'>Contact phone</label>
                            <input
                                {...register('contactNumber')}
                                placeholder="Phone"
                                className="basic-form__text-box"
                                type='text'
                                defaultValue={member.user.userProfile?.contactNumber} />
                        </div>
                    </div>
                </div>

                {needsConfirmation && (
                    <>
                        <div className="basic-form__group basic-form__group--check">
                            <span className="mr-2">Use email address</span>
                            <input
                                type="checkbox"
                                className="switchToggle"
                                {...register('requiresEmail')}
                                id='requiresEmail' />
                            <label className="switchLabel" htmlFor='requiresEmail'>&nbsp;</label>
                        </div>
                        {watchRequiresEmail ?
                            <>
                                <p className='mb-2 mt-1'>This member will be required to use the mobile app. They will be sent an email invite with their login credentials.</p>
                                <div className="basic-form__group">
                                    <label htmlFor='email' className='basic-form__text-label'>Email address</label>
                                    <input
                                        {...register('email')}
                                        placeholder="Email"
                                        type='email'
                                        className="basic-form__text-box lowercase" />
                                    {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                                </div>
                            </> : <p className='mb-3'>This member will still be required to use the mobile app. The {circle.mode === CIRCLE_MODE.mentoring ? 'Circle Mentors' : 'Circle Leaders'} will be able to access and pass on this person's unique credentials. </p>
                        }

                    </>
                )}


                <p className='mb-2 uppercase bold small text-muted-2'>Other data</p>

                <div className="row">
                    <div className="col-md-6">
                        <div className='basic-form__group'>
                            <label htmlFor='bame' className='basic-form__text-label'>Ethnicity {showBameRequired && <>* Required</>}</label>
                            <select
                                defaultValue={member.user.userProfile?.bame}
                                {...register('bame')}
                                className="basic-form__text-select">
                                <option value=''>Please select</option>
                                {ethnicities.map(el => (
                                    <optgroup key={el.id} label={el.group}>
                                        {el.details.map(detail => (
                                            <option key={detail.id} value={detail.id}>{detail.name}</option>
                                        ))}
                                    </optgroup>
                                ))}
                            </select>
                            {errors.bame && <p className='basic-form__hint'>{errors.bame.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        {circle.referral &&
                            <div className="bg-primary-light p-2">
                                <p className="small">As entered on referral:</p>
                                <p>{circle.referral.youngPersonEthnicity}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {(role === circleMembership.affectedParty || role === circleMembership.responsibleParty || role === circleMembership.participant) &&
                            <>
                                <div className="basic-form__group">
                                    <label htmlFor='name' className='basic-form__text-label'>Participant address</label>
                                    <textarea
                                        {...register('address')}
                                        placeholder="Address"
                                        className="basic-form__text-box"
                                        rows={4}
                                        defaultValue={member.user.userProfile?.address}></textarea>
                                </div>
                                <div className="basic-form__group basic-form__group--check">
                                    <span className="mr-2">Previous Record</span>
                                    <input
                                        type="checkbox"
                                        className="switchToggle"
                                        {...register('previousRecord')}
                                        id='previousRecord'
                                        defaultChecked={member.user.userProfile?.previousRecord} />
                                    <label className="switchLabel" htmlFor='previousRecord'>&nbsp;</label>
                                </div>

                                <div className="basic-form__group">
                                    <label htmlFor='healthIssues' className='basic-form__text-label'>Participant health issues</label>
                                    <textarea
                                        {...register('healthIssues')}
                                        placeholder="Health issues"
                                        className="basic-form__text-box"
                                        rows={4}
                                        defaultValue={member.user.userProfile?.healthIssues}></textarea>
                                </div>

                                <div className="basic-form__group">
                                    <label htmlFor='parentDetails' className='basic-form__text-label'>Participant parent details</label>
                                    <textarea
                                        {...register('parentDetails')}
                                        placeholder="Parent details"
                                        className="basic-form__text-box"
                                        defaultValue={member.user.userProfile?.parentDetails}
                                        rows={4}></textarea>
                                </div>

                                <div className="basic-form__group">
                                    <label htmlFor='otherRelevantInformation' className='basic-form__text-label'>Other relevant information</label>
                                    <textarea
                                        {...register('otherRelevantInformation')}
                                        placeholder="Other details"
                                        className="basic-form__text-box"
                                        defaultValue={member.user.userProfile?.otherRelevantInformation}
                                        rows={4}></textarea>
                                </div>
                            </>
                        }


                    </div>
                </div>
                {formErrors && <FormErrors errors={formErrors} />}

                <button type="submit" disabled={adding} className="btn">Save</button>
                {!needsConfirmation && <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>}
            </form >
        </>;
    }
    return null;
}

export default ParticipantEdit;