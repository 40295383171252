import { gql } from '@apollo/client';


export const UPDATE_USER = gql`
mutation updateUser($userId: ID, $firstName: String!, $lastName: String!, $email: String!, $photoUrl: String) {
    updateUser(userId: $userId, firstName: $firstName, lastName: $lastName, email: $email, photoUrl: $photoUrl) {
       errors
       user {
            id
            firstName
            lastName
            email
            isOrganizationAdmin
            photoUrl
            organization {
                id
                name
                offices {
                    id
                    name
                }
            }
       }
    }
}
`;
