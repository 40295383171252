import React, { useState, useEffect, useMemo } from "react";
import { AlertTriangle } from "react-feather";
import { useForm } from "react-hook-form";
import FormErrors from "../../../components/FormErrors";
import { useMutation } from "@apollo/client";
import { EDIT_MEMBERSHIP, GET_ORG_USERS } from "../services/graphql";

const EditMembership = ({ close, organization, user, currentUser }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [userErrors, setUserErrors] = useState(null);

    const defaultOffices = useMemo(() => {
        return organization.offices.map(office => ({
            id: office.id,
            name: office.name,
            isCircleLeader: user.officeMemberships.find(om => om.isCircleLeader && om.office.id === office.id) ? true : false,
            isAdmin: (user.isOrganizationAdmin || user.officeMemberships.find(om => om.isAdmin && om.office.id === office.id)) ? true : false
        }));
    }, [organization.offices, user.officeMemberships]);

    const [offices, setOffices] = useState(defaultOffices);

    useEffect(() => {
        setOffices(defaultOffices);
    }, [defaultOffices]);


    const { register, watch, handleSubmit, setValue } = useForm();

    const watchIsOrganizationAdmin = watch('isOrganizationAdmin', user.isOrganizationAdmin);

    const handleOfficeChange = (officeId, field) => {
        setOffices(prevOffices => prevOffices.map(office => office.id === officeId ? ({ ...office, [field]: !office[field] }) : office))
    }

    const handleAllOfficesAdmin = ({ target: { checked } }) => {
        setOffices(prevOffices => prevOffices.map(office => ({ ...office, isAdmin: checked })));
        setValue('isOrganizationAdmin', checked);
    }

    const handleAllLeaderOffices = ({ target: { checked } }) => {
        setOffices(prevOffices => prevOffices.map(office => ({ ...office, isCircleLeader: checked })));
        setValue('isLeader', checked);
    }


    const [editOfficeMemberships, { loading }] = useMutation(EDIT_MEMBERSHIP, {
        onCompleted: data => {
            if (data?.editOfficeMemberships?.errors?.length > 0) {
                setFormErrors(data.editOfficeMemberships.errors)
                return
            }
            if (data.editOfficeMemberships?.user?.id) {
                close();
            }
        },
        refetchQueries: [{ query: GET_ORG_USERS, variables: { organizationId: organization.id, showAllUsers: true } }]
    })


    const onSubmit = data => {
        const { isOrganizationAdmin, isLeader } = data;
        const validOffices = offices.filter(office => office.isCircleLeader || office.isAdmin);

        // console.log('data', data, 'valid offices', validOffices);

        if ((isLeader && validOffices.filter(office => office.isCircleLeader).length === 0) || (validOffices.length === 0)) {
            setUserErrors(true)
            return
        } else {
            setUserErrors(false);
        }

        const officesData = validOffices.map(office => {
            return {
                officeId: office.id,
                isCircleLeader: office.isCircleLeader,
                isAdmin: office.isAdmin
            }
        });

        editOfficeMemberships({
            variables: {
                userId: user.id,
                isOrganizationAdmin: user.id === currentUser.id ? user.isOrganizationAdmin : isOrganizationAdmin,
                offices: officesData,
            }
        })
    }


    return <>
  
        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="mb-3">Permissions</h4>
            <div className="row">
                <div className="col-md">
                    <h4 className="uppercase small bold text-muted-2 underline">Admin access</h4>
                    <div className="basic-form__group basic-form__group--check">
                        <span>Organization Admin </span>
                        <div className="ml-auto d-flex">
                            <input
                                type="checkbox"
                                className="switchToggle"
                                {...register('isOrganizationAdmin')}
                                id='isOrganizationAdmin'
                                onChange={handleAllOfficesAdmin}
                                defaultChecked={user.isOrganizationAdmin}
                                disabled={user.id === currentUser.id} />
                            <label className="switchLabel" htmlFor='isOrganizationAdmin'>&nbsp;</label>
                        </div>
                    </div>
                    <p className="small text-muted mb-2">When on, this person will be able to view and manage all offices</p>
                    <p className="bold mb-2">Office Level Admin</p>
                    <ul className="list-group">

                        {offices.map(office => (
                            <li className="list-item d-block mb-2" key={`admin-${office.id}`}>
                                <div className="basic-form__group basic-form__group--check">
                                    <span>{office.name}</span>
                                    <div className="ml-auto d-flex">
                                        <input
                                            disabled={watchIsOrganizationAdmin === true || user.id === currentUser.id}
                                            type="checkbox"
                                            className="switchToggle"
                                            id={`admin-${office.id}`}
                                            checked={office.isAdmin}

                                            onChange={() => handleOfficeChange(office.id, 'isAdmin')}
                                        />
                                        <label className="switchLabel" htmlFor={`admin-${office.id}`}>&nbsp;</label>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-md">
                    <h4 className="uppercase small bold text-muted-2 underline">Circle learder status</h4>
                    <div className="basic-form__group basic-form__group--check">
                        <span>Qualified circle leader </span>
                        <div className="ml-auto d-flex">
                            <input
                                type="checkbox"
                                className="switchToggle"
                                {...register('isLeader')}
                                id='isLeader'
                                defaultChecked={offices.some(o => o.isCircleLeader)}
                                onChange={handleAllLeaderOffices} />
                            <label className="switchLabel" htmlFor='isLeader'>&nbsp;</label>
                        </div>
                    </div>
                    <div style={{ display: offices.find(o => o.isCircleLeader) ? 'block' : 'none' }}>
                        <p className="bold mb-2">Member can be assigned cases from:</p>
                        <ul className="list-group">
                            {offices.map(office => (
                                <li className="list-item d-block mb-2" key={`leader-${office.id}`}>
                                    <div className="basic-form__group basic-form__group--check">
                                        <span>{office.name}</span>
                                        <div className="ml-auto d-flex">
                                            <input
                                                type="checkbox"
                                                className="switchToggle"
                                                id={`leader-${office.id}`}
                                                checked={office.isCircleLeader}
                                                onChange={() => handleOfficeChange(office.id, 'isCircleLeader')}
                                            />
                                            <label className="switchLabel" htmlFor={`leader-${office.id}`}>&nbsp;</label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center">
                <div className="d-flex">
                    <button type="submit" disabled={loading} className="btn btn-fullWidth u-display-block">{loading ? 'Saving...' : 'Save'}</button>
                    <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                </div>
                {userErrors &&
                    <div className="d-flex align-items-center ml-2">
                        <AlertTriangle className="text-danger mr-1" />
                        <div>
                            <p>No permissions</p>
                            <p className="small">Please select an Admin or Circle Leader permission to save.</p>
                        </div>
                    </div>}
            </div>
            {formErrors && <FormErrors errors={formErrors} />}
        </form>
    </>;
}

export default EditMembership;

