import React, { useState, useContext } from "react";
import Modal from "../../../../../components/Modal";
import { Plus, Star } from "react-feather";
import AddLeaders from "./AddLeaders";
import { CIRCLE_MODE } from "../../../../../utils/constants";
import { AuthContext } from "../../../../../services/auth/AuthProvider";
import styles from '../ViewCase.module.scss';

const Leaders = ({ circle }) => {

    const [addLeader, setAddLeader] = useState(false);
    const [editLeaders, setEditLeaders] = useState(false);

    const { currentUser } = useContext(AuthContext);


    return (
        <>

                {!circle.leader ? (
                    <>
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="mb-3">Who should run & manage this case?</h1>
                                <div className="p-2 bg-gray">
                                    <p className="bold lead">You need at least 1 Circle {circle.mode === CIRCLE_MODE.mentoring ? 'Mentor' : 'Leader'}</p>
                                    <p>Any person assigned here will be able to view and manage this case including case notes and circle interactions.</p>
                                </div>
                            </div>
                            <div className="col-md-4 text-right">
                                <button type="button" className="btn d-flex px-1 ml-auto" onClick={() => setAddLeader(true)}><Plus /></button>
                            </div>
                        </div>
                    </>
                ) : <>
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="mb-3">Who should run & manage this case?</h1>
                            <ul className='list-group mb-3'>
                                <li className='list-item--separator d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center py-1'>
                                        {circle.leader.user?.photoUrl ?
                                            <div className={styles.leaderPhoto} style={{ backgroundImage: `url(${circle.leader.user.photoUrl})` }}></div> :
                                            <div className={styles.leaderNoPhoto}></div>
                                        }
                                        <p>{circle.leader.user?.firstName} {circle.leader?.user?.lastName}</p>
                                    </div>
                                    <span className='ml-auto'><Star size={18} stroke='#EAA190' fill='#EAA190' /></span>
                                </li>
                                {circle.secondLeader &&
                                    <li className='list-item--separator'>
                                        <div className='d-flex align-items-center py-1'>
                                            {circle.secondLeader.user?.photoUrl ?
                                                <div className={styles.leaderPhoto} style={{ backgroundImage: `url(${circle.secondLeader.user.photoUrl})` }}></div> :
                                                <div className={styles.leaderNoPhoto}></div>
                                            }
                                            <p>{circle.secondLeader.user?.firstName} {circle.secondLeader?.user?.lastName}</p>
                                        </div>
                                    </li>
                                }
                            </ul>

                            <button type="button" className="btn" onClick={() => setEditLeaders(true)}>Edit</button>

                        </div>
                        <div className="col-md-4">
                            <div className="p-2 bg-gray">
                                <p className="bold lead d-flex align-items-center mb-2">
                                    <Star size={18} className='mr-1' stroke='#EAA190' fill='#EAA190' />
                                    Case Lead/Owner
                                </p>
                                <p>Any person assigned here will be able to view and manage this case including case notes and circle interactions.
                                </p>
                                <p>Case lead/owner is for reporting purposes only.</p>
                            </div>
                        </div>
                    </div>

                </>}

                {
                    addLeader &&
                    <Modal closeModal={() => setAddLeader(false)}>
                        <AddLeaders currentUser={currentUser} circle={circle} close={() => setAddLeader(false)} />
                    </Modal>
                }

                {
                    editLeaders &&
                    <Modal closeModal={() => setEditLeaders(false)}>
                        <AddLeaders isEdit={true} currentUser={currentUser} circle={circle} close={() => setEditLeaders(false)} />
                    </Modal>
                }
        </>

    )

}

export default Leaders;