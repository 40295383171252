import { gql } from "@apollo/client";

export const GET_REPORTS_COUNTERS = gql`
query mentoringSessionsReports($officeId: ID, $statusFilters: [String!]) {
    mentoringSessionsReports(officeId: $officeId, statusFilters: $statusFilters) {
        id
        count
        status
    }
}
`;

export const GET_SESSION_REPORTS = gql`
query mentoringSessionsReports($organizationId: ID, $officeId: ID, $statusFilters: [String!], $from: String, $to: String, $mentorId: ID) {
    mentoringSessionsReports(organizationId: $organizationId, officeId: $officeId, statusFilters: $statusFilters, from: $from, to: $to, mentorId:$mentorId) {
        id
        count
        status
        sessions {
            id
            leadMentor
            date
            time
            index
            circle {
                id
                name
                participants {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            status
            debrief {
                id
                status
            } 
        }
    }
}
`;