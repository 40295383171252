import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CIRCLE_SESSIONS, APPROVE_EXTENSION } from "../../../../services/grapqhl";
import { DEBRIEF_STATUS, SESSION_STATUS } from "../../../../../../utils/constants";
import SideModal from "../../../../../../components/SideModal";
import { Calendar, Activity, CheckCircle } from "react-feather";
import moment from "moment";
import progressImg from '../../../../../../images/icons/circle_progress.svg';
import doneImg from '../../../../../../images/icons/circle_done.svg';
import failImg from '../../../../../../images/icons/circle_fail.svg';
import { AuthContext } from "../../../../../../services/auth/AuthProvider";
import Loader from "../../../../../../components/Loader";
import ViewSession from "./ViewSession";
import FormErrors from "../../../../../../components/FormErrors";

const Sessions = ({ circleId, circleData }) => {

    const { currentUser } = useContext(AuthContext);

    const [viewSession, setViewSession] = useState(null);
    const [mutationErrors, setMutationErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_CIRCLE_SESSIONS, {
        variables: {
            circleId
        }
    });

    const isOfficeAdmin = currentUser?.officeMemberships.some(om => om.office?.id === circleData.office?.id && om.isAdmin);
    const canApproveSessionRequest = isOfficeAdmin || currentUser?.isOrganizationAdmin;

    const [approveOrRejectAdditionalSessions, { loading: approving }] = useMutation(APPROVE_EXTENSION, {
        variables: {
            circleId
        },
        onCompleted: data => {
            if (data?.approveOrRejectAdditionalSessions.errors?.length > 0) {
                setMutationErrors(data.approveOrRejectAdditionalSessions.errors);
                return;
            }
            if (data?.approveOrRejectAdditionalSessions.circle?.id) {
                setMutationErrors([]);
            }
        },
        refetchQueries: [{ query: GET_CIRCLE_SESSIONS, variables: { circleId } }]
    });


    const renderStatusIcon = status => {
        switch (status) {
            case SESSION_STATUS.new:
                return <img src={progressImg} alt="" className="mr-2" />;
            case SESSION_STATUS.completed:
                return <img src={doneImg} alt="" className="mr-2" />;
            case SESSION_STATUS.missed:
                return <img src={failImg} alt="" className="mr-2" />;
            default:
                return <p>ss</p>
        }
    }

    if (loading) return <Loader />
    if (error) return <p>{error.message}</p>


    if (data?.circle) {

        const { circle } = data;

        return (
            <>
                {canApproveSessionRequest && circle.sessionRequests.filter(r => !r.approvedOrRejectedAt).map(r => (
                    <div key={r.id} className="mb-2 card p-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="mb-0">More Sessions Requested</h3>
                            <div className="ml-auto">
                                <button disabled={approving} onClick={() => approveOrRejectAdditionalSessions({
                                    variables: {
                                        sessionRequestId: r.id,
                                        approved: true
                                    }
                                })} className="btn btn-outline mr-2">Approve</button>
                                <button disabled={approving} onClick={() => approveOrRejectAdditionalSessions({
                                    variables: {
                                        sessionRequestId: r.id,
                                        approved: false
                                    }
                                })} className="btn btn-outline mr-2">Reject</button>
                            </div>
                        </div>
                    </div>
                ))}

                <FormErrors errors={mutationErrors} />

                {circle.sessions.length > 0 ? <>
                    <ul>
                        {[...circle.sessions].sort((a, b) => b.index - a.index).map(session =>
                            <li key={session.id} className="card mb-2">
                                <button onClick={() => setViewSession(session)} className="card-body d-flex justify-content-between align-items-start">
                                    <div>
                                        <div className="d-flex align-items-center mb-2">
                                            {renderStatusIcon(session.status)}
                                            <span className="mr-2 h2 mb-0">{session.index}</span>
                                            <span className="label label-muted">{session.isOnline ? 'Online' : 'In Person'}</span>
                                        </div>
                                        {session.mentoringSessionPlannedTheme &&
                                            <p className="d-flex align-items-center">
                                                <Activity className="mr-1" /> {session.mentoringSessionPlannedTheme.name}
                                            </p>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-end flex-column align-items-end">
                                        <div className="d-flex align-items-center mb-2">
                                            <Calendar className="mr-1" />
                                            {moment(session.date).format('DD MMM YYYY')} @ {moment(session.time, 'HH:mm:ss').format('HH:mm')}
                                        </div>
                                        {session.status === SESSION_STATUS.completed && session.debrief?.status === DEBRIEF_STATUS.approved && <p className="d-flex align-items-center"><CheckCircle className="mr-1" size={18} />Approved</p>}
                                    </div>
                                </button>
                            </li>
                        )}
                    </ul>
                </> : <p>No sessions</p>
                }

                {viewSession && (
                    <SideModal closeModal={() => setViewSession(null)}>
                        <ViewSession sessionId={viewSession.id} circle={circle} />
                    </SideModal>
                )}
            </>
        )
    }
    return null;
}

export default Sessions;