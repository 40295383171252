import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { GET_OFFICE_REFERRALS, GET_ORG_REFERRALS, CREATE_CIRCLE_FROM_REFERRAL } from "../services/graphql";
import { navigate } from "@reach/router";
import { useMutation } from "@apollo/client";
import AssignToOffice from "./AssignToOffice";
import FormErrors from "../../../components/FormErrors";
import { REFERRAL_STATUS } from "../../../utils/constants";

const ReferralHeadOffice = ({ referral, organization, setShowDecline, close, currentUser, setCurrentUser }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [showCreateCase, setShowCreateCase] = useState(false);
    const [showChangeOffice, setShowChangeOffice] = useState(false);
    const [mutationErrors, setMutationErrors] = useState([]);


    const referralQueries = () => {
        if (referral.office?.id) {
            return [
                { query: GET_ORG_REFERRALS, variables: { organizationId: organization.id }, },
                { query: GET_OFFICE_REFERRALS, variables: { officeId: referral.office.id } },
            ]
        }
        return [{ query: GET_ORG_REFERRALS, variables: { organizationId: organization.id } }]
    }

    const [createCircleFromReferral, { loading }] = useMutation(CREATE_CIRCLE_FROM_REFERRAL, {
        update: (_, { data }) => {
            if (data.createCircleFromReferral?.errors?.length > 0) {
                setMutationErrors(data.createCircleFromReferral.errors);
                return;
            }
            if (data.createCircleFromReferral?.circle?.id) {
                const updatedReferrals = [];
                currentUser.organization.referrals.forEach(r => {
                    if (r.id === referral.id) {
                        updatedReferrals.push({
                            ...r,
                            status: REFERRAL_STATUS.has_case,
                        });
                    } else {
                        updatedReferrals.push(r);
                    }
                });
                const newCu = { ...currentUser, organization: { ...currentUser.organization, referrals: [...updatedReferrals] } }
                setCurrentUser(newCu);
                navigate(`/offices/${referral?.office?.id}/cases/${data.createCircleFromReferral.circle.id}`);

            }
        },
        refetchQueries: () => referralQueries()
    });
    
    const onSubmit = data => {
        const { referralSourceId, initiativeId} = data;
        createCircleFromReferral({
            variables: {
                referralId: referral.id,
                ...(initiativeId && { initiativeId: initiativeId }),
                referralSourceId
            }
        });
    }

    return (
        <>

            {!showCreateCase && !showChangeOffice ?
                <div className="d-flex mb-2">
                    <button className="btn btn-primary mr-2" onClick={() => setShowCreateCase(true)}>Create case</button>
                    <button className="btn btn-outline mr-2" onClick={() => setShowChangeOffice(true)}>Change office</button>
                    <button className="btn btn-outline" onClick={() => setShowDecline(true)}>Decline</button>
                </div>
                :
                showCreateCase ?
                    <form onSubmit={handleSubmit(onSubmit)} className="bg-primary-light radius-3 p-2  mb-2">
                        <div className="d-flex align-items-center">
                            <span className="mr-1 lead">New case:</span>
                            <select
                                {...register('initiativeId')}
                                style={{ width: 'none' }}
                                className="basic-form__text-select mb-0 mr-1"
                            >
                                <option value=''>Select initiative</option>
                                {organization.initiatives.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                            </select>
                            <select
                                style={{ width: 'none' }}
                                {...register('referralSourceId', { required: 'Please choose referral source' })}
                                className="basic-form__text-select mb-0 mr-1"
                            >
                                <option value=''>Select referral source</option>
                                {organization.referralSources.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                            </select>
                            <button type="submit" disabled={loading} className="btn btn-primary mr-4">Create</button>
                            <button type="button" onClick={() => { setShowCreateCase(false); reset() }} className="btn btn-outline ml-auto">Cancel</button>
                        </div>
                        <p className="basic-form__hint mb-0">{errors.referralSource?.message}</p>
                        <FormErrors errors={mutationErrors || []} />
                    </form> :

                    <AssignToOffice
                        referral={referral}
                        close={close}
                        organization={organization}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        setShowAssign={setShowChangeOffice}
                    />
            }
        </>
    )
}

export default ReferralHeadOffice;