import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CASE } from '../../services/grapqhl';
import Loader from '../../../../components/Loader';
import CaseDetails from './components/CaseDetails';
import CaseBaseEdit from "./components/CaseBaseEdit";
import SideModal from "../../../../components/SideModal";
import CaseHeader from "./components/CaseHeader";
import Leaders from './components/Leaders';
import Members from './components/Members';
import NotesAndDocs from './components/NotesAndDocs';
import ViewReferral from '../../../Referrals/ViewReferral';

const TABS = {
    leaders: 1,
    members: 2,
    details: 3,
    notes: 4,
    referral: 5,
}

const ViewCase = ({ caseId }) => {

    const [tab, setTab] = useState(TABS.leaders);

    const [showEdit, setShowEdit] = useState(false);

    const { data, loading, error } = useQuery(GET_CASE, {
        fetchPolicy: 'network-only',
        variables: {
            circleId: caseId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }


    if (data?.circle) {
        const { circle } = data;

        return (
            <>
                <CaseHeader
                    tabs={TABS}
                    setShowEdit={setShowEdit}
                    circle={circle}
                    tab={tab}
                    setTab={setTab}
                />

                <div className={`${tab === TABS.notes ? 'none': 'card'}`}>
                    <div className={`${tab === TABS.notes ? 'none' : 'card-body'}`}>
                        {tab === TABS.leaders && <Leaders circle={circle} />}
                        {tab === TABS.members && <Members circle={circle} />}
                        {tab === TABS.details && <CaseDetails circle={circle} />}
                        {tab === TABS.notes && <NotesAndDocs circle={circle} />}
                        {tab === TABS.referral && <ViewReferral referralItem={circle.referral} />}

                    </div>
                </div>

                {showEdit &&
                    <SideModal closeModal={() => setShowEdit(false)}>
                        <CaseBaseEdit circle={circle} close={() => setShowEdit(false)} />
                    </SideModal>
                }
            </>
        )
    }
    return null;
}

export default ViewCase;
