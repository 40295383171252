import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useEffectOnce } from 'react-use';
import ls from 'local-storage';
import { Router } from '@reach/router';

import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGGED_IN } from '../services/graphql';

import RouteCapture from './RouteCapture';
import Dashboard from '../../Dashboard';
import Cases from '../../Cases';
import ViewCase from '../../Cases/scenes/ViewCase';
import MyCases from '../../Cases/scenes/MyCases';
import Reports from '../../Reports';
import Profile from '../../Profile';
import Training from '../../Training';
import ResourceCentre from '../../ResourceCentre';
import Login from '../../Login';
import ConfirmOrgAdminUser from '../../Login/scenes/ConfirmOrgAdminUser';
import Team from '../../Team';
import LoggedInRouter from './LoggedInRouter';
import LoggedOutRouter from './LoggedOutRouter';
import Offices from '../../Offices';
import ViewOffice from '../../Offices/ViewOffice';
import OfficeTeam from '../../Team/OfficeTeam';
import Settings from '../../Settings';
import Locations from '../../Settings/scenes/Locations';
import SessionCategories from '../../Settings/scenes/SessionCategories';
import Referrals from '../../Referrals';
import CaseReports from '../../CaseReports';

const RouterAuthQuery = () => {
    const { data, error, loading } = useQuery(LOGGED_IN);
    const [ready, setReady] = useState(false);

    const [orgData, setOrgData] = useState(null);

    const auth = useContext(AuthContext);


    useEffect(() => {
        if (ls('restorativ_admin_token') && !auth.currentUser && ls('restorativ_admin_user') && ls('restorativ_admin_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('restorativ_admin_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls('restorativ_admin_token') && !auth.currentUser && ls('restorativ_admin_user') && ls('restorativ_admin_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('restorativ_admin_user')));
        }
        setReady(true);
    });

    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return <p>loading</p>
        }

        if (data && ready) {
            return (
                <Router>
                    <RouteCapture path='/'>
                        {
                            auth.currentUser ? (
                                <LoggedInRouter orgData={orgData} setOrgData={setOrgData} path='/' default>
                                    {orgData &&
                                        <>
                                            <Dashboard path='/' default />
                                            <Profile path='/profile' />

                                            <Reports path='/reports' />
                                            <Settings path='/settings' >
                                                <Locations path='locations' default />

                                                {["session-types", "session-themes", "cancellation-reasons", "session-breaks"].map(page => <SessionCategories key={page} path={page} />)}
                                            </Settings>

                                            <ResourceCentre path='/resourceCentre' />
                                            <Training path='/training' />
                                            <Referrals path='/referrals' />
                                            <Team path='/team' />
                                            <MyCases path='/myCases' />

                                            <Offices path='/allOffices' />
                                            <ViewOffice path='/offices/:officeId' />
                                            <Cases path='/offices/:officeId/cases' />
                                            <OfficeTeam path='/offices/:officeId/team' />
                                            <ViewCase path='offices/:officeId/cases/:caseId' />
                                            <ViewCase path='cases/:caseId' />
                                            <Referrals path='/offices/:officeId/referrals' />
                                            <CaseReports path='/offices/:officeId/caseReports' />


                                        </>
                                    }
                                </LoggedInRouter>
                            ) : (
                                <LoggedOutRouter path='/' default>
                                    <Login path='/' default />
                                    <ConfirmOrgAdminUser path='/confirm_admin/:code' />
                                </LoggedOutRouter>
                            )
                        }
                    </RouteCapture>
                </Router>
            )
        }
    }

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );

}

export default RouterAuthQuery;
