import React, { useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { EDIT_CASE_NOTE, GET_CASE_NOTES } from "../../../../services/grapqhl";
import FormErrors from "../../../../../../components/FormErrors";
import { GET_S3_UPLOAD_URL_QUERY } from "../../../../../ResourceCentre/services/graphql";
import { useForm } from 'react-hook-form';
import { File } from "react-feather";
import axios from 'axios';

const EditNote = ({ close, note, circle }) => {

    const client = useApolloClient();

    const [initLoading, setInitLoading] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);


    const onSelectFile = (files) => {
        if (files.length) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null)
        }
    }

    const [editCaseNote, { loading }] = useMutation(EDIT_CASE_NOTE, {
        onCompleted: (data) => {
            if (data?.editCaseNote?.errors?.length > 0) {
                setFormErrors(data.editCaseNote.errors)
                return
            }
            if (data?.editCaseNote?.caseNote?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE_NOTES,
                variables: {
                    circleId: circle.id,
                },
                fetchPolicy: 'network-only'
            }
        ],
    })


    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm({
        mode: 'onSubmit'
    });



    const onSubmit = data => {
        const { text, documentName } = data;
        if (selectedFile) {
            const extension = selectedFile.name.split('.').pop().toLowerCase();
            const filename = `Document-${new Date().getTime()}.${extension}`;
            setInitLoading(true);
            client.query({
                query: GET_S3_UPLOAD_URL_QUERY,
                variables: {
                    fileName: filename
                }
            }).then(r => {
                setInitLoading(false);
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                axios.put(r.data.s3UploadUrl, selectedFile, options).then(s3r => {
                    editCaseNote({
                        variables: {
                            caseNoteId: note.id,
                            text: text,
                            documentName: documentName,
                            documentUrl: r.data.s3UploadUrl.substr(0, r.data.s3UploadUrl.indexOf('?'))
                        }
                    })
                })
            });
        } else {
            editCaseNote({
                variables: {
                    caseNoteId: note.id,
                    text: text,
                    ...(note.document && { 
                        documentName: documentName, 
                        documentUrl: note.document.url
                    })
                }
            })
        }
    }

    return <>
        <h2>Edit Note</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-form__group">
                <textarea
                    {...register('text', { required: "Please add a text note" })}
                    className="basic-form__text-box"
                    placeholder="Case notes"
                    defaultValue={note.text}
                    rows={8}></textarea>
                {errors.text && <p className="basic-form__hint">{errors.text.message}</p>}
            </div>



            
                <>
                    <p>Document</p>
                    <div className="basic-form__group">
                    { note.document && <p className="mb-2"><a download href={note.document.url} className="d-flex heavy"><File className="mr-1" size={20} /> {note.document.name}</a></p>}

                        <input
                            {...register('file')}
                            onChange={e => onSelectFile(e.target.files)}
                            type='file'
                            className='basic-form__text-box' />
                    </div>
                    <label htmlFor="documentName">Document name</label>
                    <div className="basic-form__group">
                        <input
                            {...register('documentName', {
                                validate: {
                                    required: value => {
                                        if (!value && (selectedFile || note.document?.name)) return 'Please add a name';
                                        return true;
                                    },
                                },
                            })}
                            className="basic-form__text-box"
                            type='text'
                            placeholder="Document name"
                            defaultValue={note.document?.name} />
                    </div>
                </>
            
            {errors.documentName && <p className="basic-form__hint">{errors.documentName.message}</p>}
            {formErrors && <FormErrors errors={formErrors} />}

            <button disabled={initLoading || loading} className="btn mr-2">Save</button>
            <button className="btn btn-muted" onClick={() => close()} type="button">Cancel</button>
        </form>
    </>;
}

export default EditNote;