import { gql } from "@apollo/client";

export const EDIT_OFFICE = gql`
mutation editOffice($officeId: ID!, $name: String!) {
    editOffice(officeId: $officeId, name: $name) {
        errors
        office {
            id
            name
        }
    }
}
`;

export const GET_OFFICES = gql`
query organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
        id
        name
        offices {
            id
            name
        }
    }
}
`;