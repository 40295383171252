import React, { useState } from "react";
import CategoryItem from "./CategoryItem";
import AddCategory from "./AddCategory";
import { useMutation } from "@apollo/client";
import { ADD_CATEGORY, EDIT_CATEGORY, DELETE_CATEGORY, GET_ORGANIZATION, UPDATE_ORG_SESSION_TRESHOLD } from "../services/graphql";
import { MENTORING_CATEGORY_TYPES } from "../../../utils/constants";

const CategoriesWrapper = ({ organization, type, items }) => {

    const [showEdit, setShowEdit] = useState(null);

    const [editErrors, setEditErrors] = useState([]);
    const [addErrors, setAddErrors] = useState([]);

    const sortedItems = type === 'SessionBreaks' ? [...items]?.sort((a, b) => a - b) : items;


    const getTypeText = () => {
        if (type === MENTORING_CATEGORY_TYPES.cancellationReason) {
            return 'Cancellation Reasons'
        }
        if (type === MENTORING_CATEGORY_TYPES.sessionCateg) {
            return 'Session Types'
        }
        if (type === MENTORING_CATEGORY_TYPES.sessionTheme) {
            return 'Session Themes'
        }
        if (type === 'SessionBreaks') {
            return 'Session Breaks'
        }
        return null
    }


    const [addCategory, { loading }] = useMutation(ADD_CATEGORY, {
        onCompleted: data => {
            if (data.addCategory?.errors?.length > 0) {
                setAddErrors(data.addCategory.errors);
                return;
            }
            if (data?.addCategory?.category?.id) {
                addErrors.length > 0 && setAddErrors([]);
            }
        },
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId: organization.id } }]
    });

    const [editCategory, { loading: editing }] = useMutation(EDIT_CATEGORY, {
        onCompleted: data => {
            if (data.editCategory?.errors?.length > 0) {
                setEditErrors(data.editCategory.errors);
                return;
            }
            if (data.editCategory?.category?.id) {
                setShowEdit(null);
                editErrors.length > 0 && setEditErrors([]);

            }
        },
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId: organization.id } }]
    });

    const [updateOrganizationSessionThreshold, { loading: updating }] = useMutation(UPDATE_ORG_SESSION_TRESHOLD, {
        onCompleted: data => {
            if (data.updateOrganizationSessionThreshold?.errors?.length > 0) {
                setEditErrors(data.updateOrganizationSessionThreshold.errors);
                return;
            }
            if (data.updateOrganizationSessionThreshold?.organization?.id) {
                //done
            }
        },
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId: organization.id } }]
    });

    const [deleteCategory, { loading: deleting }] = useMutation(DELETE_CATEGORY, {
        onCompleted: data => {
            if (data.deleteCategory?.errors?.length > 0) {
                setAddErrors(data.deleteCategory.errors);
                return;
            }
        },
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId: organization.id } }]
    });

    const onAddCateg = name => {
        if (type === 'SessionBreaks') {
            return updateOrganizationSessionThreshold({
                variables: {
                    organizationId: organization.id,
                    sessionThreshold: [...items, +name],
                }
            })
        } else {
            return addCategory({
                variables: {
                    organizationId: organization.id,
                    name,
                    type: type
                }
            });
        }
    }

    const onEditCateg = (data) => {
        editCategory({
            variables: {
                categoryId: data.id,
                name: data.name,
            }
        });
    }
    const onDeleteCateg = (data) => {
        if (type === 'SessionBreaks') {
            updateOrganizationSessionThreshold({
                variables: {
                    organizationId: organization.id,
                    sessionThreshold: items.filter(item => item !== +data),
                }
            })
        }
        else {
            deleteCategory({
                variables: {
                    categoryId: data.id
                }
            })
        }
    }

    return (
        <>
            <h1 className="mb-2">{getTypeText()}</h1>
            {sortedItems?.length > 0 ?
                <ul className="list-group mb-2">
                    {sortedItems.map((item, i) => (
                        <CategoryItem
                            loading={editing || deleting}
                            item={item}
                            key={item.id ?? `item-${i}`}
                            onEdit={onEditCateg}
                            onDelete={onDeleteCateg}
                            organization={organization}
                            showEdit={showEdit}
                            setShowEdit={setShowEdit}
                            editErrors={editErrors}
                            type={type}
                        />
                    ))}
                </ul> : <p>No {getTypeText()} Added</p>}
            <AddCategory addErrors={addErrors} type={type} loading={loading || updating} onAddItem={onAddCateg} />

        </>
    )
}

export default CategoriesWrapper;