import { gql } from '@apollo/client';

export const GET_TRAINING_EVENTS = gql`
query organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
        id
        trainingEvents {
            id
            eventDate
            eventTime
            information
            location
            title
            website
            isMandatory
            ratings {
                id
                author {
                    id
                    firstName
                    lastName
                }
                comment
                rating
            }
        }
    }
}

`;


export const ADD_TRAINING_EVENT = gql`
mutation addTrainingEvent($organizationId: ID!, $title: String!, $eventDate: String!, $eventTime: String!, $location: String!, $website: String!, $information: String!, $isMandatory: Boolean!) {
    addTrainingEvent(organizationId: $organizationId, title: $title, eventDate: $eventDate, eventTime: $eventTime, location: $location, website: $website, information: $information, isMandatory: $isMandatory) {
        errors
        trainingEvent {
            id
            eventDate
            eventTime
            information
            location
            title
            website
            isMandatory
        }
    }
}
`;

export const EDIT_TRAINING_EVENT = gql`
mutation editTrainingEvent($trainingEventId: ID!, $organizationId: ID!, $title: String!, $eventDate: String!, $eventTime: String!, $location: String!, $website: String!, $information: String!, $isMandatory: Boolean!) {
    editTrainingEvent(trainingEventId: $trainingEventId, organizationId: $organizationId, title: $title, eventDate: $eventDate, eventTime: $eventTime, location: $location, website: $website, information: $information, isMandatory: $isMandatory) {
        errors
        trainingEvent {
            id
            eventDate
            eventTime
            information
            location
            title
            website
            isMandatory
        }
    }
}
`;

export const DELETE_TRAINING_EVENT = gql`
mutation deleteTrainingEvent($trainingEventId: ID!) {
    deleteTrainingEvent(trainingEventId: $trainingEventId) {
        errors
        deleted
    }
}
`;
