import { gql } from '@apollo/client';


export const HOURS_PER_MONTH_LEADER_CASE = gql`
query organization($organizationId: ID!, $circleLeaderId: ID, $year: Int, $circleId: ID) {
    organization(organizationId: $organizationId) {
        id
        createdAt
        totalHoursPerMonth(year: $year, circleLeaderId: $circleLeaderId, circleId: $circleId) {
            id
            month
            hours
        }
    }
}
`;

export const OFFICE_HOURS_PER_MONTH_LEADER_CASE = gql`
query office($officeId: ID!, $circleLeaderId: ID, $year: Int, $circleId: ID) {
    office(officeId: $officeId) {
        id
        createdAt
        totalHoursPerMonth(year: $year, circleLeaderId: $circleLeaderId, circleId: $circleId) {
            id
            month
            hours
        }
    }
}
`;


export const ORGANIZATION_REPORT_DATA = gql`
query organization($organizationId: ID!, $month: Int, $year: Int) {
    organization(organizationId: $organizationId) {
        id
        numberOfAffectedPartiesSupported
        numberOfResponsiblePartiesSupported
        createdAt
        totalHoursPerCaseTypePerMonth(month: $month, year: $year) {
            id
            name
            value
        }
        leaders {
            id
            firstName
            lastName
            allCases {
                id
                name
            }
        }
    }
}
`;


const reportQuery = `
id
threatReportsCounter
bamePercentage
outcomesReachedPercentage
averages {
    timeFromPrecircleToClose
    timeLogs
    timePerCase
    timeToPrecircle
}
caseTypes {
    id
    name
    value
}
initiatives {
    id
    name
    value
}
referralSources {
    id
    name
    value
}
personas {
    id
    name
    value
}
 `;

export const OFFICE_REPORTS = gql`
query office($officeId: ID!, $month: Int, $year: Int, $threatReportYear: Int) {
    office(officeId: $officeId) {
        id
        name
        numberOfAffectedPartiesSupported
        numberOfResponsiblePartiesSupported
        createdAt
        reports(threatReportYear: $threatReportYear) {
            ${reportQuery}
        }
        totalHoursPerCaseTypePerMonth(month: $month, year: $year) {
            id
            name
            value
        }
        leaders {
            id
            firstName
            lastName
            allCases {
                id
                name
                office {
                    id
                }
            }
        }
        allThreats {
            id
            createdAt
                    author {
                id
                firstName
                lastName
            }
            circle {
                id
                name
            }
            details
        }
    }
}
`;


export const REPORTS= gql`
query reports($threatReportYear: Int) {
    reports(threatReportYear: $threatReportYear) {
     ${reportQuery}
    }
}
`;


export const GET_OFFICE_TIME_LOGS_CSV = gql`
query officeTimeLogsCsv($officeId: ID!, $year: Int!, $circleId: ID, $circleLeaderId: ID) {
    officeTimeLogsCsv(officeId: $officeId, year: $year, circleId: $circleId, circleLeaderId: $circleLeaderId) 
}
`;


