import React, { useState } from "react";
import { useMutation } from '@apollo/client';
import { ADD_TRAINING_EVENT, EDIT_TRAINING_EVENT, GET_TRAINING_EVENTS } from '../services/graphql';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import FormErrors from '../../../components/FormErrors';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const TrainingForm = ({ close, itemToEdit = null, organizationId }) => {

    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        eventDate: Yup.string().required('Date is required'),
        eventTime: Yup.string().required('Time is required'),
        location: Yup.string().required('Location is required'),
        website: Yup.string().required('Website is required'),
        information: Yup.string().required('Information is required'),
    });

    const {
        register,
        handleSubmit,
        control,

        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [addTrainingEvent, { loading }] = useMutation(ADD_TRAINING_EVENT, {
        onCompleted: data => {
            if (data.addTrainingEvent.errors && data.addTrainingEvent.errors.length > 0) {
                setFormErrors(data.addTrainingEvent.errors);
                return;
            }
            if (data.addTrainingEvent?.trainingEvent?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TRAINING_EVENTS, variables: { organizationId }, fetchPolicy: 'network-only' }]
    });

    const [editTrainingEvent, { loading: editing }] = useMutation(EDIT_TRAINING_EVENT, {
        onCompleted: data => {

            if (data.editTrainingEvent.errors && data.editTrainingEvent.errors.length > 0) {
                setFormErrors(data.editTrainingEvent.errors);
                return;
            }
            if (data.editTrainingEvent?.trainingEvent?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TRAINING_EVENTS, variables: { organizationId }, fetchPolicy: 'network-only' }]
    });

    const onSubmit = data => {
        const { title, eventDate, eventTime, location, website, information, isMandatory } = data;
        if (itemToEdit) {
            editTrainingEvent({
                variables: {
                    organizationId,
                    trainingEventId: itemToEdit.id,
                    title,
                    eventDate: moment(eventDate).format('DD/MM/YYYY'),
                    eventTime: moment(eventTime).format('hh:mm a'),
                    location,
                    website,
                    information,
                    isMandatory,
                }
            })
        } else {
            addTrainingEvent({
                variables: {
                    organizationId,
                    title,
                    eventDate: moment(eventDate).format('DD/MM/YYYY'),
                    eventTime: moment(eventTime).format('hh:mm a'),
                    location,
                    website,
                    information,
                    isMandatory,
                }
            });
        }
    }

    return <>
        <h1>{itemToEdit ? 'Edit' : 'New'} training course</h1>
        <form className='form mb-3 mt-3' onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-form__group">
                <label htmlFor='title' className='basic-form__text-label'>Course Title</label>
                <input
                    {...register('title')}
                    placeholder="Course title"
                    className="basic-form__text-box"
                    defaultValue={itemToEdit?.title} />
                {errors.title && <p className="basic-form__hint">{errors.title.message}</p>}
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="basic-form__group">
                        <label htmlFor='eventDate' className='basic-form__text-label'>Date</label>
                        <Controller
                            control={control}
                            name="eventDate"
                            defaultValue={itemToEdit?.eventDate ? moment(itemToEdit.eventDate).toDate() : ''}
                            render={({ field }) => (
                                <ReactDatePicker
                                    {...field}
                                    dateFormat="dd/MM/yyyy"
                                    className="basic-form__text-box picker"
                                    placeholderText="Select date"
                                    autoComplete="off"
                                    minDate={moment().toDate()}
                                    selected={field.value}
                                />
                            )}
                        />
                        {errors.eventDate && <p className='basic-form__hint'>{errors.eventDate.message}</p>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="basic-form__group">
                        <label htmlFor='eventTime' className='basic-form__text-label'>Time</label>
                        <Controller
                            control={control}
                            name="eventTime"
                            defaultValue={itemToEdit?.eventTime ? moment(itemToEdit.eventTime, 'hh:mm a').toDate() : ''}
                            render={({ field }) => (
                                <ReactDatePicker
                                    {...field}
                                    dateFormat="hh:mm a"
                                    className="basic-form__text-box picker"
                                    placeholderText="Select time"
                                    autoComplete="off"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    selected={field.value}
                                />
                            )}
                        />
                        {errors.eventTime && <p className='basic-form__hint'>{errors.eventTime.message}</p>}
                    </div>
                </div>
            </div>

            <div className="basic-form__group">
                <label htmlFor='location' className='basic-form__text-label'>Location</label>
                <input
                    {...register('location')}
                    placeholder="Location"
                    className="basic-form__text-box"
                    defaultValue={itemToEdit?.location} />
                {errors.location && <p className="basic-form__hint">{errors.location.message}</p>}
            </div>
            <div className="basic-form__group">
                <label htmlFor='website' className='basic-form__text-label'>Website</label>
                <input
                    {...register('website')}
                    placeholder="Website"
                    className="basic-form__text-box"
                    defaultValue={itemToEdit?.website} />
                {errors.website && <p className="basic-form__hint">{errors.website.message}</p>}
            </div>

            <div className="basic-form__group">
                <label htmlFor='information' className='basic-form__text-label'>Information</label>
                <textarea
                    {...register('information')}
                    placeholder="information"
                    className="basic-form__text-box"
                    defaultValue={itemToEdit?.information}
                    rows={5}></textarea>
                {errors.information && <p className="basic-form__hint">{errors.information.message}</p>}
            </div>
            <div className="basic-form__group basic-form__group--check mb-3">
                <span className="mr-2">Mandatory Training </span>
                <input
                    type="checkbox"
                    className="switchToggle"
                    {...register('isMandatory')}
                    id='isMandatory'
                    defaultChecked={itemToEdit?.isMandatory} />
                <label className="switchLabel" htmlFor='isMandatory'>&nbsp;</label>
            </div>
            <button type="submit" disabled={loading || editing} className="btn btn-fullWidth u-display-block">{loading || editing ? 'Saving...' : 'Save'}</button>
            <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
            {formErrors && <FormErrors errors={formErrors} />}
        </form>
    </>;
}

export default TrainingForm;