import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react';
import cx from 'classnames';
import { useApolloClient } from '@apollo/client';
import ImageFadeIn from 'react-image-fade-in';
import ReactCrop from 'react-image-crop';
import axios from 'axios';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '../../components/Modal';
import { GET_S3_UPLOAD_URL_QUERY } from '../../services/graphql';

import styles from './ProfileImage.module.scss';

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4;

const ProfileImage = ({ defaultImg, imgName, previewWidth = 100, onSave, btnText = 'Edit', savingImage, setShowCropModal, showCropModal, width }) => {

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width, minWidth: width, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const client = useApolloClient();


    const getResizedCanvas = (canvas, newWidth, newHeight) => {
        const tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        const ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(
            canvas,
            0,
            0,
            canvas.width,
            canvas.height,
            0,
            0,
            newWidth,
            newHeight
        );

        return tmpCanvas;
    }

    const generateCrop = (previewCanvas, crop) => {
        if (!crop || !previewCanvas) {
            return;
        }

        if (crop.width === 0 || crop.height === 0) {
            alert('Cropping area is too small');
            return;
        }

        const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = 'go-img';
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            setShowCropModal(true);
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const saveNewImage = async () => {
        const newSavedImg = await generateCrop(previewCanvasRef.current, completedCrop);
        if (newSavedImg) {
            const filename = `${imgName}${new Date().getTime()}.jpeg`;
            client.query({
                query: GET_S3_UPLOAD_URL_QUERY,
                variables: {
                    fileName: filename
                }
            }).then(r => {
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                axios.put(r.data.s3UploadUrl, newSavedImg, options).then(s3r => {
                    onSave({
                        variables: {
                            photoUrl: r.data.s3UploadUrl.substr(0, r.data.s3UploadUrl.indexOf('?'))
                        },
                    });
                })
            })
        }
    }

    return (
        <Fragment>
            <div className={styles.container}>
                <ImageFadeIn width={previewWidth} className={cx(styles.savedImage)} src={defaultImg} />
                <div className={styles.controls}>
                    <input className={styles.inputFile} id='newImage' name='newImage' type='file' accept='image/*' onChange={onSelectFile} />
                    <label className='outline' htmlFor='newImage'>{btnText}</label>
                </div>
            </div>
            {
                showCropModal && (
                    <Modal closeModal={() => {
                        setUpImg(null);
                        setShowCropModal(false);
                    }}>
                        <h1>Crop profile image</h1>
                        <div className={styles.modalContent}>
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={c => setCrop(c)}
                                onComplete={c => setCompletedCrop(c)}
                            />
                            <div className={styles.modalControls}>
                                <canvas
                                    className={styles.previewImage}
                                    ref={previewCanvasRef}
                                    style={{
                                        width,
                                        height: width
                                    }}
                                />
                                <button
                                    className='btn'
                                    type='button'
                                    disabled={savingImage || crop.width === 0 || crop.height === 0}
                                    onClick={() => {
                                        saveNewImage();
                                    }}
                                >
                                    {savingImage ? 'Saving' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

        </Fragment>
    )
}

export default ProfileImage;