import React, { useContext, useMemo } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import ls from 'local-storage';
import Reports from '../Reports';
import OfficeReportsContent from '../Reports/OfficeReportsContent';
import MyCases from '../Cases/scenes/MyCases';

const Dashboard = () => {

  const { currentUser } = useContext(AuthContext);
  const officeContext = ls('office_context') && JSON.parse(ls('office_context'));

  const officeAdminMemberships = currentUser?.officeMemberships?.filter(m => m.isAdmin);

  const officeCLMemberships = currentUser?.officeMemberships?.filter(m => m.isCircleLeader);

  const getOfficeAdminContext = useMemo(() => {
    let selectedOffice;
    if (officeContext && officeAdminMemberships?.find(om => om.office.id === officeContext.id)) {
      selectedOffice = officeContext;
    }
    else {
      selectedOffice = officeAdminMemberships?.map(om => om.office)[0];
    }
    return selectedOffice;
  }, [officeContext, officeAdminMemberships]);



  const renderDashContent = () => {
    if (currentUser?.isOrganizationAdmin) {
      return <Reports />
    }

    if (getOfficeAdminContext) {
      return <OfficeReportsContent officeId={getOfficeAdminContext.id} />
    }

    if (officeCLMemberships && !getOfficeAdminContext) {
      return <MyCases />
    }
    return null
  }

  return (
    <>
      {renderDashContent()}
    </>
  )
}


export default Dashboard;
