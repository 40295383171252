import React, { useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from "@apollo/client";
import { GET_OFFICE_LEADERS, ADD_CIRCLE_LEADERS, EDIT_CIRCLE_LEADERS, GET_CASE } from "../../../services/grapqhl";
import Loader from "../../../../../components/Loader";
import FormErrors from "../../../../../components/FormErrors";

const AddLeaders = ({ circle, close, currentUser, isEdit }) => {

    const [formErrors, setFormErrors] = useState([]);

    //INFO: mutation addCircleLeaders NOT called for Leaders from My Cases page, as they are already made leaders

    const leaderOfficeMemberships = currentUser?.officeMemberships?.filter(om => om.isCircleLeader && !om.isAdmin && om.office?.id === circle.office?.id);

    const isLeaderInCaseOffice = !currentUser.isOrganizationAdmin && leaderOfficeMemberships.find(om => om.office?.id === circle.office.id)

    const schema = Yup.object().shape({
        leaderId: Yup.string().required('Lead is required'),
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            leaderId: circle.leader?.user?.id,
            secondLeaderId: circle.secondLeader?.user?.id,
        }
    });

    const { data, loading, error } = useQuery(GET_OFFICE_LEADERS, {
        variables: {
            officeId: circle.office?.id
        }
    });

    const [addCircleLeaders, { loading: saving }] = useMutation(ADD_CIRCLE_LEADERS, {
        onCompleted: data => {
            if (data?.addCircleLeaders?.errors?.length > 0) {
                setFormErrors(data.addCircleLeaders.errors)
                return
            }
            if (data.addCircleLeaders.circle?.id) {
                close();
            }
        },
        refetchQueries: [{ query: GET_CASE, variables: { circleId: circle.id } }]
    })

    const [editCircleLeaders, { loading: editing }] = useMutation(EDIT_CIRCLE_LEADERS, {
        onCompleted: data => {
            if (data?.editCircleLeaders?.errors?.length > 0) {
                setFormErrors(data.editCircleLeaders.errors)
                return
            }
            if (data.editCircleLeaders.circle?.id) {
                close();
            }
        },
        refetchQueries: [{ query: GET_CASE, variables: { circleId: circle.id } }]
    })

    const [watchLeaderId, watchSecondLeaderId] = watch(['leaderId', 'secondLeaderId']);

    const onSubmit = data => {
        const { leaderId, secondLeaderId } = data;

        if (isEdit) {
            editCircleLeaders({
                variables: {
                    circleId: circle.id,
                    leaderId: leaderId,
                    secondLeaderId: secondLeaderId === '' ? null : secondLeaderId,
                }
            });
        } else {
            addCircleLeaders({
                variables: {
                    circleId: circle.id,
                    leaderId: leaderId,
                    secondLeaderId: secondLeaderId === '' ? null : secondLeaderId,
                }
            });
        }
    }


    if (loading) return <Loader />;
    if (error) return <div>{error.message}</div>;

    if (data?.office) {

        const leaders = data.office.leaders;
        const secondLeaders = [...data.office.leaders];
        const cuLeader = leaders.find(l => l.id === currentUser.id);


        return <>
            <h2>{isEdit ? 'Edit' : 'Add'} Case/Circle Leaders</h2>
            <form className='form mb-4' onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    {isLeaderInCaseOffice ?
                        <>
                            <p className='bold'>Case Lead: {cuLeader?.firstName} {cuLeader?.lastName}</p>
                            <input type='hidden' value={currentUser.id} {...register('leaderId')} />
                        </> :
                        <>
                            <label htmlFor='leaderId' className='basic-form__text-label'>Case lead* (required)</label>
                            <select
                                {...register('leaderId')}
                                className="basic-form__text-select">
                                <option value=''>Please select </option>
                                {leaders.filter(leader => leader.id !== watchSecondLeaderId).map(leader => <option key={leader.id} value={leader.id}>{leader.firstName} {leader.lastName}</option>)}
                            </select>
                            {errors.leaderId && <p className='basic-form__hint'>{errors.leaderId.message}</p>}
                        </>
                    }
                </div>

                <div className='basic-form__group mb-3'>
                    <label htmlFor='secondLeaderId' className='basic-form__text-label'>Case 2nd lead</label>
                    <select
                        {...register('secondLeaderId')}
                        className="basic-form__text-select">
                        <option value=''>Please select </option>
                        {secondLeaders.filter(leader => leader.id !== watchLeaderId).map(leader => <option key={`second-${leader.id}`} value={leader.id}>{leader.firstName} {leader.lastName}</option>)}
                    </select>
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <button type="submit" disabled={saving || editing} className="btn">{isEdit ? 'Edit' : 'Add'} Leaders</button>
                <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
            </form>
        </>;
    }
    return null;
}

export default AddLeaders;