import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GET_CASE, EDIT_CASE, TOGGLE_ARCHIVED_CIRCLE } from '../../../services/grapqhl';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import FormErrors from '../../../../../components/FormErrors';
import { AuthContext } from '../../../../../services/auth/AuthProvider';
import { CIRCLE_MODE, CIRCLE_STATUS } from '../../../../../utils/constants';

const CaseBaseEdit = ({ circle, close }) => {

    const { currentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);

    const [archive, setArchive] = useState(null);
    const [formErrorsArchive, setFormErrorsArchive] = useState([]);

    const [editCase, { loading }] = useMutation(EDIT_CASE, {
        onCompleted: data => {
            if (data.editCircle.errors && data.editCircle.errors.length > 0) {
                setFormErrors(data.editCircle.errors);
                return;
            }
            if (data.editCircle && data.editCircle.circle) {
                close()
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE,
                variables: {
                    circleId: circle.id,
                }
            }
        ],
    });

    const schema = Yup.object().shape({
        referralSourceId: Yup.string().required('Referral source is required'),
        ...(circle.mode !== CIRCLE_MODE.mentoring && {
            caseTypeId: Yup.string().required('Case type is required')
        }),
        name: Yup.string().required('Name is required'),
    });

    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const onSubmit = data => {
        const { caseTypeId, name, initiativeId, referralSourceId, otherId, conductCircleVirtually } = data;

        const condVirt = circle.mode === CIRCLE_MODE.mentoring ? false : circle.status < CIRCLE_STATUS.started ? conductCircleVirtually : circle.conductCircleVirtually;

        editCase({
            variables: {
                circleId: circle.id,
                conductCircleVirtually: condVirt,
                name,
                initiativeId: !initiativeId ? null : initiativeId,
                caseTypeId: !caseTypeId ? null : caseTypeId,
                referralSourceId,
                otherId: !otherId ? null : otherId,
            }
        });
    }


    const [toggleArchiveCircle, { loading: saving }] = useMutation(TOGGLE_ARCHIVED_CIRCLE, {
        onCompleted: data => {
            if (data.toggleArchiveCircle.errors?.length > 0) {
                setFormErrorsArchive(data.toggleArchiveCircle.errors)
                return
            }
            if (data.toggleArchiveCircle?.circle?.id) {
                setArchive(null);
            }
        },
        refetchQueries:
            [{ query: GET_CASE, variables: { circleId: circle.id } }]
    })

    return <>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
            {(circle.mode !== CIRCLE_MODE.mentoring && circle.status < CIRCLE_STATUS.started && !circle.caseManagementOnly) &&
                <>
                    <div className="basic-form__group basic-form__group--check mb-1">
                    <span className="mr-2">Use virtual chatroom</span>
                        <input
                            type="checkbox"
                            className="switchToggle"
                            {...register('conductCircleVirtually')}
                            id='conductCircleVirtually'
                            defaultChecked={circle.conductCircleVirtually} />
                        <label className="switchLabel" htmlFor='conductCircleVirtually'>&nbsp;</label>
                    </div>
                    <p className='small mb-2'>Even if you decide not to use the virtual chatroom the mobile app will be used to manage communications with participants and upload repair agreements</p>
                </>
            }

            <div className='basic-form__group'>
                <label htmlFor='initiativeId' className='basic-form__text-label'>Initiative</label>
                <select
                    defaultValue={circle.initiative?.id}
                    {...register('initiativeId')}
                    className="basic-form__text-select">
                    <option value=''>Please select</option>
                    {currentUser.organization.initiatives.map(el => (
                        <option key={`initiative-${el.id}`} value={el.id}>{el.name}</option>
                    ))}
                </select>
            </div>

            <div className='basic-form__group'>
                <label htmlFor='referralSourceId' className='basic-form__text-label'>Referral Source* Required</label>
                <select
                    defaultValue={circle.referralSource?.id}
                    {...register('referralSourceId')}
                    className="basic-form__text-select">
                    <option value=''>Please select</option>
                    {currentUser.organization.referralSources.map(el => (
                        <option key={`referralSource-${el.id}`} value={el.id}>{el.name}</option>
                    ))}
                </select>
                {errors.referralSourceId && <p className='basic-form__hint'>{errors.referralSourceId.message}</p>}
            </div>
            {currentUser.organization.others.length > 0 &&
                <div className='basic-form__group'>
                    <label htmlFor='otherId' className='basic-form__text-label'>Others</label>
                    <select
                        defaultValue={circle.other?.id}
                        {...register('otherId')}
                        className="basic-form__text-select">
                        <option value=''>Please select </option>
                        {currentUser.organization.others.map(el => (
                            <option key={`other-${el.id}`} value={el.id}>{el.name}</option>
                        ))}
                    </select>
                </div>
            }

            <div className="basic-form__group">
                <label htmlFor='name' className='basic-form__text-label'>Case name</label>
                <input
                    {...register('name')}
                    placeholder="Name"
                    defaultValue={circle.name}
                    className="basic-form__text-box" />
                {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
            </div>

            {circle.mode !== CIRCLE_MODE.mentoring &&
                <div className='basic-form__group'>
                    <label htmlFor='caseTypeId' className='basic-form__text-label'>Case Type* Required</label>
                    <select
                        defaultValue={circle.caseType?.id}
                        {...register('caseTypeId')}
                        className="basic-form__text-select">
                        <option value=''>Please select</option>
                        {currentUser.organization.caseTypes.map(el => (
                            <option key={`caseType-${el.id}`} value={el.id}>{el.name}</option>
                        ))}
                    </select>
                    {errors.caseTypeId && <p className='basic-form__hint'>{errors.caseTypeId.message}</p>}
                </div>
            }
            {formErrors && <FormErrors errors={formErrors} />}
            <button type="submit" disabled={loading} className="btn">Save edits</button>
            <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
        </form>

        <hr className='separator' />

        <button type='button' className="btn btn-outline" onClick={() => setArchive(circle.archived ? 'unarchive' : 'archive')}>{circle.archived ? 'Unarchive' : 'Archive'}</button>
        {archive && (
            <div>
                <h3 className="mt-4 mb-2">Are you sure you want to {archive === 'archive' ? 'archive' : 'unarchive'} this case?</h3>
                <button
                    disabled={saving}
                    onClick={() => toggleArchiveCircle({
                        variables: {
                            circleId: circle.id,
                            archive: circle.archived ? false : true
                        }
                    })}
                    className="btn mr-2"
                >Yes</button>
                <button type='button' onClick={() => setArchive(null)} className="btn btn-outline mr-2">No</button>
                {formErrorsArchive && <FormErrors errors={formErrorsArchive} />}

            </div>

        )}

    </>;
}

export default CaseBaseEdit;