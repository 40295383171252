import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { htmlLegendPlugin, getColors } from '../utils';

ChartJS.register(ArcElement, Tooltip, Legend);

const CATEG_TYPES = {
    caseTypes: 1,
    initiatives: 2,
    referralSources: 3
}

const CategoriesBreakdown = ({ isOrgReport, reports }) => {

    const [selectedVal, setSelectedVal] = useState(CATEG_TYPES.caseTypes);
    const [selectedCateg, setSelectedCateg] = useState([]);

    useEffect(() => {
        switch (+selectedVal) {
            case CATEG_TYPES.caseTypes:
                return setSelectedCateg(reports.caseTypes);
            case CATEG_TYPES.initiatives:
                return setSelectedCateg(reports.initiatives);
            case CATEG_TYPES.referralSources:
                return setSelectedCateg(reports.referralSources);
            default:
                return setSelectedCateg([]);
        }
    }, [selectedVal, reports.caseTypes, reports.initiatives, reports.referralSources]);

    // const sortedTypes = [...selectedCateg].sort((a, b) => b.value - a.value);
    // A-Z, not value (June change)
    const sortedTypes = [...selectedCateg].sort((a, b) => a.name.localeCompare(b.name));

    const chartData = (sortedTypes) => {
        return {
            labels: [...sortedTypes.map(type => type.name)],
            datasets: [
                {
                    data: [...sortedTypes.map(el => el.value)],
                    backgroundColor: getColors(sortedTypes, 'value', sortedTypes.length),
                },
            ],
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            htmlLegend: {
                containerID: 'legend-container-first',
            },
            legend: {
                display: false,
            },
        },
    };

    if (sortedTypes) {
        return (
            <div className="mb-3">
                <div className={`${"card"} ${isOrgReport ? "card-blue" : ""}`}>
                    <div className="card-body">
                        <h2>Case Types, Referrals & Initiatives</h2>
                        <select style={{ maxWidth: '200px' }} onChange={(e) => setSelectedVal(e.target.value)} className='basic-form__text-select'>
                            <option value={CATEG_TYPES.caseTypes}>Case types</option>
                            <option value={CATEG_TYPES.referralSources}>Referral Sources</option>
                            <option value={CATEG_TYPES.initiatives}>Initiatives</option>
                        </select>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <div className="px-2">
                                    <Doughnut
                                        plugins={[htmlLegendPlugin]}
                                        data={chartData(sortedTypes)}
                                        options={options}
                                    />
                                </div>

                            </div>
                            <div className="col-md-8">
                                <div id="legend-container-first"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default CategoriesBreakdown;