import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { REJECT_REFERRAL, GET_ORG_REFERRALS } from "../services/graphql";
import { useMutation } from "@apollo/client";
import FormErrors from "../../../components/FormErrors";

const DeclineReferral = ({ close, setShowDecline, setCurrentUser, currentUser, organization, referral }) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [mutationErrors, setMutationErrors] = useState([]);

    const [rejectReferral, { loading: rejecting }] = useMutation(REJECT_REFERRAL, {
        update: (_, { data }) => {
            if (data?.rejectReferral?.errors?.length > 0) {
                setMutationErrors(data.rejectReferral.errors);
                return;
            }
            if (data.rejectReferral.referral?.id) {
                const updatedReferrals = [];
                currentUser.organization.referrals.forEach(r => {
                    if (r.id === referral.id) {
                        updatedReferrals.push({
                            ...r,
                            status: data.rejectReferral.referral.status,
                            office: data.rejectReferral.referral.office
                        });
                    } else {
                        updatedReferrals.push(r);
                    }
                });
                const newCu = { ...currentUser, organization: { ...currentUser.organization, referrals: [...updatedReferrals] } }
                setCurrentUser(newCu);

                setShowDecline(false);
                close();
            }
        },
        refetchQueries: [{ query: GET_ORG_REFERRALS, variables: { organizationId: organization.id } }]
    });


    const onSubmit = data => {
        const { rejectedReason } = data;
        rejectReferral({
            variables: {
                referralId: referral.id,
                rejectedReason
            }
        });
    }

    return (
        <div className="bg-primary-light radius-3 p-2  mb-2">
            <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                    <form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center width-full">
                        <span className="mr-1 lead">Reason:</span>
                        <input
                            type="text"
                            className="basic-form__text-box mr-1"
                            {...register('rejectedReason', { required: 'Please add a reason' })}
                        />
                        <button disabled={rejecting} onClick={() => ''} className="btn btn-primary mr-1">Decline</button>
                    </form>

                </div>
                <div className="col-md-4 d-flex align-items-center">
                    <button onClick={() => setShowDecline(false)} className="btn btn-outline ml-auto">Cancel</button>
                </div>
            </div>
            <p className="basic-form__hint mb-0">{errors.rejectedReason?.message}</p>
            <FormErrors errors={mutationErrors || []} />
        </div>
    )
}

export default DeclineReferral;