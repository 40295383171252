import { gql } from "@apollo/client";
import { referralQuery } from "../../../utils/constants";

export const GET_ORG_REFERRALS = gql`
query organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
        id
        referrals {
            id
            status
            createdAt
            addedBy {
                id
                firstName
                lastName
            }
            referralDate
            circle {
                id
                name
                leader {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            office {
                id
                name
            }
            referrerEmail
            referrerFirstName
            referrerLastName
            referrerCompany
            referrerDepartment
            referrerPhoneNumber
            rejectedReason
            youngPersonFirstName
            youngPersonLastName
        }
    }
}
`;

export const GET_OFFICE_REFERRALS = gql`
query office($officeId: ID!) {
    office(officeId: $officeId) {
        id
        referrals {
            id
            status
            createdAt
            addedBy {
                id
                firstName
                lastName
            }
            referralDate
            circle {
                id
                name
                leader {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            office {
                id
                name
            }
            referrerEmail
            referrerFirstName
            referrerLastName
            referrerCompany
            referrerDepartment
            referrerPhoneNumber
            rejectedReason
            youngPersonFirstName
            youngPersonLastName
        }
    }
}
`;

export const GET_REFERRAL = gql`
query referral($referralId: ID!) {
    referral(referralId: $referralId) {
        id
        ${referralQuery}
    }
}
`;

export const ASSIGN_REFERRAL_TO_OFFICE = gql`
mutation assignReferralToOffice($referralId: ID!, $officeId: ID!) {
    assignReferralToOffice(referralId: $referralId, officeId: $officeId) {
        errors
        referral {
            id
            status
            office {
                id
                name
            }
        }
    }
}
`;

export const REJECT_REFERRAL = gql`
mutation rejectReferral($referralId: ID!, $rejectedReason: String!) {
    rejectReferral(referralId: $referralId, rejectedReason: $rejectedReason) {
        errors
        referral {
            id
            rejectedReason
            status
            office {
                id
            }
        }
    }
}
`;

export const CREATE_CIRCLE_FROM_REFERRAL = gql`
mutation createCircleFromReferral($referralId: ID!,  $initiativeId: ID, $referralSourceId: ID!) {
    createCircleFromReferral(referralId: $referralId, initiativeId: $initiativeId, referralSourceId: $referralSourceId) {
        errors
        circle {
           id
           status
        }
    }
}
`;


export const CREATE_PDF_REFERRAL = gql`
mutation createReferral($organizationId: ID!, $file: Upload!, $firstName: String!,  $lastName: String!, $referralDate: String!, $officeId: ID) {
    createReferral(organizationId: $organizationId, file: $file, firstName: $firstName, lastName: $lastName, referralDate: $referralDate, officeId: $officeId) {
        errors
        referral {
           id
           ${referralQuery}
        }
    }
}
`;